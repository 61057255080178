  .deals-container {
      padding: 1rem;
      flex: 1;
      overflow-y: auto;
      width: 100%;
  }

  .deal-form {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 1rem;
    justify-content: space-between;
  }

  .deal-form input {
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 5px;
      font-size: 16px;
  }

  .deal-form button {
      padding: 10px;
      background-color: #4CAF50;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
      transition: background-color 0.3s ease;
  }

  .deal-form button:hover {
      background-color: #45a049;
  }

  .deal-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
  }

  .deal-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 5px;
      padding: 10px;
      transition: background-color 0.3s ease;
  }

  .deal-item:hover {
      background-color: #f1f1f1;
  }

  .deal-item span,
  .deal-item input {
      flex: 1;
      margin: 0 10px;
  }

  .deal-item button {
      padding: 5px 10px;
      background-color: #f44336;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
      transition: background-color 0.3s ease;
  }

  .deal-item button:hover {
      background-color: #d32f2f;
  }

  .deal-item button:first-of-type {
      background-color: #4CAF50;
      margin-right: 1rem;
  }

  .deal-item button:first-of-type:hover {
      background-color: #45a049;
  }


  @media (max-width: 1024px) {
    .deal-form input {
      font-size: 14px;
    }
  
    .deal-form button {
      font-size: 14px;
    }
  

  
    .deal-item span, .deal-item input {
      margin: 5px 0;
    }
  
    .deal-form {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 1rem;
      justify-content: space-between;
    }
  }
  
  @media (max-width: 768px) {
    .deal-form input {
      font-size: 12px;
    }
  
    .deal-form button {
      font-size: 12px;
    }
  
    .deal-item {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .deal-item span, .deal-item input {
      margin: 5px 0;
    }
  
    .deal-item button {
      margin-top: 5px;
      width: 100%;
      text-align: center;
    }
  }
  
  @media (max-width: 664px) {
    .deal-form input {
      font-size: 12px;
    }
  
    .deal-form button {
      font-size: 12px;
    }
  
    .deal-item {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .deal-item span, .deal-item input {
      margin: 5px 0;
    }
  
    .deal-item button {
      margin-top: 5px;
      width: 100%;
      text-align: center;
    }
  }
  
  @media (max-width: 480px) {
    .deal-form input {
      font-size: 10px;
    }
  
    .deal-form button {
      font-size: 10px;
    }
  
    .deal-item {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .deal-item span, .deal-item input {
      margin: 5px 0;
    }
  
    .deal-item button {
      margin-top: 5px;
      width: 100%;
      text-align: center;
    }
  }
  
  @media (orientation: portrait) {
    @media (min-width: 768px) {
      .deal-form input {
        font-size: 14px;
      }
  
      .deal-form button {
        font-size: 14px;
      }
  
 
      .deal-item span, .deal-item input {
        margin: 5px 0;
      }
  
 
    }
  }