/* Login.css */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 140px);
  width: 100vw;
  max-width: 1200px;
  padding: 1rem 1rem 0 1rem;
}

.login-form {
  width: 100%;
  max-width: 400px;
  margin: auto;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}


.login-form h1 {
  text-align: center;
  color: #fff;
  margin-bottom: 2rem;
}

.input-container {
  position: relative;
  display: flex;
}

.input-container .material-symbols-outlined {
  position: absolute;
  padding: 0px 0px 0px 5px;
  bottom: 50%;
  font-size: 16px;
}

.login-form input {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  padding: 5px 5px 5px 30px;
}

.login-form button {
  width: 100%;
  padding: 1rem;
  background-color: #2F8FF6;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-form button:hover {
  background-color: #1C6ABE;
}

.error-message {
  color: red;
  text-align: center;
}

.login-modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--card-bg-color);
  padding: 1rem;
  border-radius: var(--border-radius);
  backdrop-filter: blur(30px);
  width: 100%;
  height: 100%;
  z-index: 999;

}

.login-modal-content {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background: var(--card-bg-color);
  padding: 1rem;
  border-radius: var(--border-radius);
  transition: transform 0.3s ease;
  animation: slideUp 0.5s ease forwards;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  width: 50vw;
  min-height: 500px;
  max-height: 80vh;
  justify-content: space-around;
  align-items: center;
}

.lightmode .login-modal-content {
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    background: rgba(0, 0, 0, 0.1);
}

.login-modal-content form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 50%;
}
.reservation-modal-content .btnwrap h3,
.reservation-modal-content .btnwrap li{
  font-size: 2rem;
}
.login-modal-content form h1 {
  font-size: 40px;
  text-align: center;
}

.login-modal-content .material-symbols-outlined {
  position: relative;
  top: 50%;
  padding-left: 5px;
  font-size: 30px;
}

.login-modal-content form input {
  width: 100%;
  font-size: 30px;
  padding: 5px 5px 5px 30px;
}

.login-modal-content form .LoginBtn {
  font-size: 30px;
  width: 100%;
  border: none;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
}

.login-modal-content form .LoginBtn:hover {
  font-size: 30px;
  width: 100%;
  border: none;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px;
}

.login-modal-content :nth-child(1).loginWrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.login-modal-content :nth-child(1).loginWrap .idpwWrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 55%;
  width: 100%;
  padding-top: 30px;
}



.login-modal-content .loginWrap .logintype {
  display: flex;
}

.signupwrap {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  text-align: center;
}

.signup {
  font-size: 1.5rem;
  color: #4a54e1;
  border: none;
  background-color: inherit;
}

.signup:hover {
  color: #4a54e1;
  font-weight: bold;
}


/* 회원가입 modal */

.signup-modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--card-bg-color);
  padding: 1rem;
  border-radius: var(--border-radius);
  backdrop-filter: blur(30px);
  width: 100%;
  height: 100%;

}

.signup-modal-content {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background: var(--card-bg-color);
  padding: 1rem;
  border-radius: var(--border-radius);
  transition: transform 0.3s ease;
  animation: slideUp 0.5s ease forwards;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  width: 50vw;
  min-height: 500px;
  z-index: 44;
  max-height: 80vh;
}

.signup-modal-content form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 50%;
  min-height: 500px;
  max-height: 80vh;
}

.signup-modal-content button {
  font-size: 30px;
  width: 100%;
  border: none;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
}

.signup-modal-content button:hover {
  background: rgba(0, 0, 0, 0.7);
}

.signup-modal-content input {
  width: 100%;
  font-size: 30px;
  padding: 5px;
}



/* 반응형 디자인 */
@media (max-width: 768px) {
  .login-form {
    padding: 1rem;
  }

  .signup-modal-content {
    width: 75vw;
  }

  .login-modal-content {
    width: 75vw;
  }
}

@media (max-width: 664px) {}

@media (max-width: 480px) {

  .login-modal-content,
  .signup-modal-content {
    width: 90vw;
  }

  .login-form {
    padding: 0.5rem;
    margin: 0 1rem;
  }

  .signup-modal-content {
    width: 75vw;
  }

  .login-modal-content {
    width: 75vw;
  }

  .login-form input,
  .login-form button {
    padding: 0.75rem 1.5rem;
  }
}