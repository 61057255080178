
/* 컨테이너 스타일 */

.LHCreate_Container {
    padding: 1rem;
    padding-top: 5rem;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80vh;
    max-height: 100vh;
    width: 100vw;
    max-width: 1200px;
    margin: 0 auto;
    gap: 15px;
    justify-content: space-between;
}
.lightmode .LHCreate_Container .Title{color: #333;}
.LHCreate_Container .Title{
    text-align: center;
    user-zoom: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    font-size: 20px;
color: #fff;
font-weight: bold;
}
/* 제목 입력 필드 스타일 */

.lightmode .LHCreate_Container input[type="text"] {color: #333;}

/* Quill 스타일 */
.LHCreate_Container .quillwrap{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow: hidden;
}
.LHCreate_Container .file-info{
align-self: end;}
.LHCreate_Container .file-info li{display: flex;}
.LHCreate_Container .file-info li .remove-btn{margin-left: 5px;border: 0;background: none;}
.LHCreate_Container .file-info li .remove-btn:hover{color: red; cursor: pointer;}

/* 제목 입력 필드 스타일 */
.LHCreate_Container input[type="text"] {
    padding: 10px;
    font-size: 1.2rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    background: none;
    color: #fff;
}
.lightmode .LHCreate_Container input[type="text"] {
    color: #333;
}
.LHCreate_Container .ql-snow .ql-editor code {
    background: #424242;
    color: #fff;
}

/* 툴바와 에디터 스타일 */
#toolbar {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: -16px;
    border: 1px solid #ccc;
    border-radius: 4px 4px 0 0;
    padding: 5px;
    width: 100%;
    color: #ccc;
}

.LHCreate_Container .quill {
    width: 100%;
    height: 100%;
}

.LHCreate_Container .ql-container {
    border-radius: 0 0 4px 4px;
    height: 85%;
    overflow: auto;
}

.LHCreate_Container .ql-toolbar.ql-snow {
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: 4px 4px 0 0;
}

.LHCreate_Container .ql-editor {
    border: 1px solid #ccc;
    border-top: none;
    padding: 10px;
    color: inherit !important; /* 스타일 덮어쓰기를 방지 */
}

.lightmode .LHCreate_Container .ql-editor.ql-blank::before {color: #333;}
.LHCreate_Container .ql-editor.ql-blank::before {
    color: #ccc;
    opacity: 1;
}


/* 태그 선택 스타일 */
.tags-selection {
    display: flex;
    gap: 0.5rem;
    max-width: 100%;
    padding: 5px 0;
    flex-direction: row;
    align-self: start;
    overflow-x: auto;
    overflow-y: hidden;
    min-height: 3rem;
}

.tags-selection h3 {
    width: 100%;
    font-size: 1rem;
    white-space: nowrap;
    margin: auto;
}

.tags-selection label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.9rem;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
}

.tags-selection input[type="checkbox"] {
    accent-color: #007bff;
}

/* 작성 버튼 스타일 */
.LHCreate_Container .create-btn {
padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    background: #007bff;
    color: white;
    cursor: pointer;
    align-self: flex-end;
}

.LHCreate_Container .create-btn:hover {
    background: #0056b3;
}

@media (max-width: 1024px) {

}

@media (max-width: 768px) {

}

@media (max-width: 664px) {
 
}

@media (max-width: 480px) {
    .LHCreate_Container .create-btn {
        padding: 3px 6px;
      }
    .LHCreate_Container .ql-container {
    
        height: 70%;
    }
}

@media (orientation: portrait) {
  @media (min-width: 768px) {

  }
}
