.Learninghub_Container {
    padding: 1rem;
    padding-top: 5rem;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 100vh;
    width: 100vw;
    max-width: 1200px;
}
.Learninghub_Container .Title{
    text-align: center;
    margin-bottom: 1.5rem;
    user-zoom: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    font-size: 20px;
}
.Learninghub_Container .LH_Wrap{
    display: flex;
    width: 100%;
    height: 100%;
    gap: 20px;
}
.Learninghub_Container .LH_Wrap .search {
    position: relative;
}
.Learninghub_Container .LH_Wrap .icon {
    position: absolute;
    right: 0.5rem;
    top: 0.4rem;
    line-height: 2rem;
    font-size: 1.3rem;
    pointer-events: none; 
}
.search input[type="text"]:focus + .material-symbols-outlined {
    display: none;
}

.Learninghub_Container .search input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
}

.Learninghub_Container .search-and-tags {
    margin-bottom: 20px;
}

.Learninghub_Container .search-and-tags button {
    margin: 5px;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
}

.Learninghub_Container .LH_Wrap .search {
    position: relative;
}
.Learninghub_Container .selected {
    background-color: #007bff;
    color: white;
}

.Learninghub_Container .LHcontentsWrap{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.Learninghub_Container .LHcontentsWrap .pagination{
    display: flex;
    width: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
    gap: 15px;
    
}
.Learninghub_Container .LHcontentsWrap .pagination button{
    padding: 3px;
    width: 1.5rem;
    height: 1.5rem;
    text-align: center;
    font-size: 0.7rem;
    font-weight: bolder;
    border-radius: 3px;
}
.Learninghub_Container .LHcontentsWrap .pagination .page-input input{
    width: 1.5rem;
    height: 1.5rem;
    text-align: center;
}

.Learninghub_Container .LHcontents .LHpost {
    display: flex;
    gap: 10px;
    margin: 10px 0;
    width: 100%;
    border-bottom: 0.2px solid;
    justify-content: space-between;
    color: #9698A6;
    padding: 5px 0;
}
.Learninghub_Container .LHcontents .LHpost:hover{
    text-decoration-line:underline;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.1);
}
    .Learninghub_Container .create-btn:hover {
        background: #0056b3;
    }

    .Learninghub_Container .create-btn {
        padding: 10px 20px;
        font-size: 1rem;
        border: none;
        border-radius: 4px;
        background: #007bff;
        color: white;
        cursor: pointer;
        align-self: flex-end;
    }


/* 검색과 태그가 기본적으로 좌측에 위치 */
.search-and-tags {
    display: flex;
    flex-direction: column;
    width: 20%;
}

/* 태그 스크롤 스타일 */
.search-and-tags.scrollable-x .tag-list {
    display: flex;
    overflow-x: auto;
    gap: 0.5rem;
    padding-bottom: 0.5rem;
}

.search-and-tags.scrollable-x .tag-list button {
    white-space: nowrap;
}

.search-and-tags.scrollable-x {
    display: flex;
    align-items: center;
}

/* 1024px 이하에서 검색과 태그를 위로 배치 */
@media (max-width: 1024px) {
    .LH_Wrap {
        flex-direction: column;
    }

    .search-and-tags {
        order: -1;
        width: 100%;
        white-space: nowrap;
        overflow-x: auto;
    }
}

/* 768px 이하에서 검색과 태그를 위로 배치 */
@media (max-width: 768px) {
    .Learninghub_Container .LHcontents .LHpost {
        flex-direction: column;
    }
    .LH_Wrap {
        flex-direction: column;
    }

    .search-and-tags {
        order: -1;
        width: 100%;
        white-space: nowrap;
        overflow-x: auto;
    }
}

/* 664px 이하에서 검색과 태그를 위로 배치 */
@media (max-width: 664px) {
    .LH_Wrap {
        flex-direction: column;
    }

    .search-and-tags {
        order: -1;
        width: 100%;
        white-space: nowrap;
        overflow-x: auto;
    }
}

/* 480px 이하에서 검색과 태그를 위로 배치 */
@media (max-width: 480px) {
    .LH_Wrap {
        flex-direction: column;
    }
    .Learninghub_Container .create-btn {
        padding: 3px 6px;
    }
    .search-and-tags {
        order: -1;
        width: 100%;
        white-space: nowrap;
        overflow-x: auto;
    }
}

/* 세로 모드에서 768px 이상일 때 검색과 태그를 위로 배치 */
@media (orientation: portrait) and (min-width: 768px) {
    .LH_Wrap {
        flex-direction: column;
    }

    .search-and-tags {
        order: -1;
        width: 100%;
        white-space: nowrap;
        overflow-x: auto;
    }
}
