.UserInfoWrap {
  padding: 1rem;
  padding-top: 50px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 100%;
  width: 100vw;
  max-width: 1200px;
}
.detail-container {
  visibility: hidden; 
  height: 0;
  overflow: hidden; 
  transition: all 0.3s ease;
}
.Perform-Title{
  color: #fff;
  background: rgba(0,0,0,0.1);
}
.Perform-Title:hover{
  outline: 1px solid #fff;
  background: rgba(0,0,0,0.3);
}
.Title{
  text-align: center;
}
.detail-container.show {
  visibility: visible; 
  height: auto; 
}
.barcode{
  width:250px;
  height: 150px;
  background: url('../images/barcode.png');
  background-size: cover;
  background-color: #fff;
}
li{
  padding: 10px 0px 10px 0px;

}
li button{
  width: 100%;
}