
.header-menu {
    display: flex;
    position: absolute;
    flex-direction: row;
    width: 100%;
    z-index: 999;
    background: rgba(22, 22, 23, .8);
    justify-content: space-between;
    height: 4rem;
}

.header-menu a{
color: #fff;
font-size: 1rem;
}

.header-menu .hamberger{
color: #fff;
cursor: pointer;
z-index: 999;
}
.header-menu .hamberger:hover{
    color: #007BFF;
    transition: color 0.3s ease;
    }


.header-menu a:hover{
color: #007BFF;
transition: color 0.3s ease;
}

.header-menu .logo {
    margin-bottom: 1rem;
    margin-top: 1rem;
    margin-left: 1rem;
    width: 124px;
    height: 35px;
    background: url("../images/LOGO.png");
    border: none;
  }
  .header-menu .logo2 {
    margin-bottom: 1rem;
    margin-top: 1rem;
    margin-left: 1rem;
    width: 124px;
    height: 35px;
    background: url("../images/LOGO.png");
    border: none;
  }
  .open{
  }
.menu {
    font-size: 1rem;
    color: #333;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    backdrop-filter: blur(30px);
    background: rgba(22, 22, 23, .8);
    z-index: 800;
    position: absolute;
    width: 100%;
    /* height: 50vh; */
    /* right: 0px; */
    /* top: 4rem; */
    /* right: 6px; */
    /* padding-top: 4rem;*/
}
.hamberger{
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
}
.topNav{
    display: flex;
    width: 80%;
    justify-content: space-around;
    align-items: center;
}
.topNav .togo{
    line-height: 30px;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    
}
.menu a{
        
    padding: 10px;    
    width: 100%;
    padding-left: 20px;
    font-weight: 600;
    color: #fff;
}
.menu a:hover{
    color: #007BFF;
transition: color 0.3s ease;
}
.header-top{
    display: flex;
    right: 16px;
    top: 16px;
    position: absolute;
    flex-direction: column;
    font-size: 1rem;
}
.header-top a{
    background: rgba(0,0,0,0.5);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    
    border-radius: 10px;
    padding: 5px 0px 5px 0px;
    z-index: 999;
    font-size: 0.7rem;
    width: 4rem;
    margin-bottom: 0.5rem;
}
.header-top a:hover{
    outline: 1px solid #fff;
    background: rgba(0,0,0,0.3);
}
.LoginBtn{
    width: 4rem;
    background: rgba(0,0,0,0.5);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    
    border-radius: 10px;
    padding: 5px 0px 5px 0px;
    z-index: 999;
    font-size: 0.7rem;
    border: none;
}
.LoginBtn:hover{
    outline: 1px solid #fff;
    background: rgba(0,0,0,0.3);
}
.userlink{
    width: 80px;
    
    border-radius: 10px;
    padding: 5px 0px 5px 0px;
    z-index: 999;
    text-align: center;
}

/* 반응형 레이아웃 */
@media (max-width: 768px) {

    .hamberger{
        display: block;
    }
.header-menu{
}
.ERP-Wrap .topNav .module{
    padding: 0.5rem;
}
.ERP-Wrap .topNav{
display: flex;
}
.topNav{display: none;}

  }
  
  @media (max-width: 664px) {

    .hamberger{
        display: block;
    }
  .header-menu{
  }
.topNav{display: none;}
.ERP-Wrap .topNav .module{
    padding: 0.5rem;
}
.ERP-Wrap .topNav{
            display: block;
        position: absolute;
        right: 1rem;
        text-align: right;
        width: auto;
        top: 3rem;
        background: rgba(0, 0, 0, 0.1);
        -webkit-backdrop-filter: blur(30px);
        backdrop-filter: blur(30px);
        text-align: left;
        z-index: 999;
}
  }
  
  @media (max-width: 480px) {

    .hamberger{
        display: block;
    }
.header-menu{
}

.topNav{display: none;}
.ERP-Wrap .topNav .module{
    padding: 0.5rem;
}
.ERP-Wrap .topNav{
            display: block;
        position: absolute;
        right: 1rem;
        text-align: right;
        width: auto;
        top: 3rem;
        background: rgba(0, 0, 0, 0.1);
        -webkit-backdrop-filter: blur(30px);
        backdrop-filter: blur(30px);
        text-align: left;
        z-index: 999;
}
  }
