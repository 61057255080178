.edms-container {
    display: flex;
    height: 100vh;
    width: 100%;
  }
  .lightmode .edms-container h2{
    color: #333;
  }
.edms-container input{
    width: 80%;
  }
.edms-container textarea{
    width: 80%;
    resize: none;
  }
  .draft_create_page .categoryWrap,
  .draft_create_page .titleWrap,
  .draft_create_page .detailWrap,
  .draft_create_page .dateWrap,
  .selectedApprovalWrap
  {padding-top: 1rem;  }
  .draft_create_page button{margin-top: 1rem;  }

  .edms-sidebar {
    width: 200px;
    background-color: #f0f0f0;
    padding: 20px;
    height: 100%;
    box-shadow: 2px 0 5px rgba(0,0,0,0.1);
  }
  .darkmode .edms-sidebar {
    background: rgba(0, 0, 0, 0.1);
  }
  .edms-container .dateWrap{
    display: flex;
  }
  .edms-container label{padding-right: 1rem;}
  .edms-container .dateWrap label:nth-child(3){padding-left: 1rem;}
  .edms-container label{
    white-space: nowrap;
  }
  .edms-container .titleWrap{display: flex;}
  .edms-container .detailWrap {display: flex;}
  .edms-container .titleWrap input{width: 100%;}
  .edms-container .detailWrap textarea{width: 100%;
  height: 10vh;}
  
  .approvalLine {
    position: absolute;
    right: 1rem;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.6);
    bottom: 20%;
}
  .selectedApprovalWrap{
    height: 7rem;
    overflow-y: auto;
  }
  .approvalLine li,
  .selectedApproval{
    padding-top: 0;
    cursor: pointer;
    padding: 0.3rem 0rem 0.3rem 0rem;
  }

  .selectedApproval:hover,
  .approvalLine li:hover{
    text-decoration: underline;
  } 
  .sidebar-item {
    margin-bottom: 10px;
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.2s ease;
    display: flex;
    justify-content: space-between;
  }
  .sidebar-item .alarm{
    background: #ff0000;
    color: #fff;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    text-align: center;
}
  
  .sidebar-item:hover,
  .sidebar-item.active {
    background-color: #ddd;
  }
  
  .edms-content {
    padding: 1rem;
    flex-grow: 1;
    overflow-y: auto;
  }
  
  .sidebar-item.active {
    background-color: #ddd; 
    color: #000;
    font-weight: bold; 
  }


  

  @media (max-width: 1024px) {
    .edms-sidebar {
      width: 180px;
      padding: 15px;
    }
    .sidebar-item {
      font-size: 14px;
      padding: 8px;
    }
  }
  
  @media (max-width: 768px) {
    .edms-sidebar {
      width: 160px;
      padding: 10px;
    }
    .sidebar-item {
      font-size: 12px;
      padding: 6px;
    }
    .edms-container *{
      font-size: 12px;
    }
  }
  
  @media (max-width: 664px) {
    .edms-container {
      flex-direction: column;
    }
    .edms-sidebar {
      width: 100%;
      height: auto;
      box-shadow: none;
    }
    .sidebar-item {
      display: inline-block;
      width: calc(100% / 2 - 10px);
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .edms-container {
      height: auto;
    }
    .edms-sidebar {
      width: 100%;
      height: auto;
      box-shadow: none;
    }
    .sidebar-item {
      display: inline-block;
      width: calc(100% / 2 - 10px);
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
  
  @media (orientation: portrait) {
    @media (min-width: 768px) {
      .edms-container {
        flex: row;
      }
      .edms-sidebar {
        width: 20%;
        height: auto;
        box-shadow: none;
      }
    }
  }