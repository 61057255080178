.TicketWrap {
    padding: 1rem;
    padding-top: 50px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: calc(100vh - 121px);
    width: 100vw;
    max-width: 1200px;
}

.TicketWrap .Title {
    text-align: center;
    color: #fff;
    margin-bottom: 1.5rem;
    user-zoom: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    font-size: 3rem;
    z-index: 10;
}
.logout{
    z-index: 999;
}
.TicketWrap .TypesWrap {
    overflow-y: auto;
    height: 80vh;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.lightmode .TicketWrap .TypesWrap .Types {
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    color: #333;
    background: rgba(0, 0, 0, 0.1);
}
.lightmode .TicketWrap .TypesWrap .Types:hover{
    outline: 1px solid #333;
}
.lightmode .TicketBookWrap .ContentsWrap .contents{
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    background: rgba(0, 0, 0, 0.1);
}
.lightmode .TicketBookWrap .ContentsWrap .contents:hover{
    outline: 1px solid #333;
  }

.TicketWrap .Types {
    height: 20vh;
    width: 98%;
    padding: 16px;
    margin: 16px 3px 0 3px;
    font-size: 2rem;
    text-align: center;
    line-height: calc(20vh - 16px);
    background: rgba(0, 0, 0, 0.1);
}

.forticket div:nth-child(1) {
    top: 200px;
}

.forticket div:nth-child(2) {
    bottom: 700px;
    top: auto;
}


/* 반응형 레이아웃 */
@media (max-width: 768px) {}

@media (max-width: 664px) {}


@media (max-width: 480px) {}