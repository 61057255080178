.hrm-Container {
    display: flex;
    height: 100vh;
    width: 100%;
}

.hrm-Container .hrm-sidebar {
    width: 200px;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 20px;
    height: 100%;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.hrm-Container .hrm-sidebar li {
    margin-bottom: 10px;
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.2s ease;
}

.hrm-Container .hrm-sidebar li:hover {
    background-color: #ddd;
}

.hrm-Container .hrm-sidebar button {
    background-color: #3A67EA;
    border: none;
    color: white;
    padding: 0.5rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    border-radius: 12px;
    transition-duration: 0.4s;
    width: 7rem;
}

.hrm-Container .hrm-sidebar button:hover {
    background-color: white;
    color: black;
    outline: 2px solid #3A67EA;
}


.hrm-Container .Modal {
        align-items: start;
        background-color: rgba(0, 0, 0, 0.1);
        top: 30%;
        height: 60%;
}

.hrm-Container .Modal h2 {
  text-align: center;
  width: 100%;
  padding-bottom: 1rem;
}

.hrm-Container .Modal div {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 1rem;
    white-space: nowrap;
}
.hrm-Container .Modal input {
width: 50%;
}

.hrm-Container .Modal .btnWrap {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.hrm-Container .Modal .btnWrap button {
    background-color: #3A67EA;
    border: none;
    color: white;
    padding: 0.5rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    border-radius: 12px;
    transition-duration: 0.4s;
    width: 7rem;
}

.hrm-Container .Modal .btnWrap button:nth-child(2) {
    background-color: #6e6e6e;
    color: #333;
}


.hrm-Container .Modal button:hover {
    background-color: white;
    color: black;
    outline: 2px solid #3A67EA;
}

.hrm-Container .Modal .btnWrap button:nth-child(2):hover {
    background-color: white;
    color: black;
    outline: 2px solid #bfbfbf;
}