.TodoList-Wrap {
  padding: 1rem;
  padding-top: 50px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 100%;
  width: 100vw;
  max-width: 1200px;
}

.TitleBar {
  display: flex;
  justify-content: space-between;
  align-items: center; /* 세로 중앙 정렬을 위해 추가 */
  margin-bottom: 20px;
}
.TodoList-Wrap .Title {
  text-align: center;
  color: #fff;
  margin-bottom: 1.5rem;
  user-zoom: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  font-size: 20px;
}

.AddTodo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
}
.AddTodo .AddTitle{
  margin-top: 20px;
}

.AddTodo div {
  display: flex;
  width: 70%;
  justify-content: space-between;  
  align-items: center;
  margin-top: 20px;
}
.AddTodo input {
  padding: 5px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 70%;
}
.AddTodo textarea {
  padding: 5px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  height: 300px;
  resize: none;
  width: 70%;
  margin-top: 20px;
}

.onlyread{
  color: #999;
  -webkit-user-select: none; 
  -moz-user-select: none;   
  -ms-user-select: none;      
  user-select: none;       
}
.AddTodo button {

  margin-top: 20px;
}
.TodoList-Wrap button {
  padding: 5px 10px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}
.TodoList-Wrap button:hover {
  background: #005abb;
}

.TodoList-Wrap .TodoList {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  text-align: center;
  border-top: 1px solid #ccc;;
  width: 100%;
}

.TodoList-Wrap .TodoList .ListTitleWrap {
  display: flex;
  width: 100%;
  padding: 10px;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  color: #333;
}


.TodoList-Wrap .TodoList li {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.2s;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  width: 70%;
  color: #333;
}

.plenty {
background:#77dd77
}

.little {
background:#fdfd96;
}

.expired {
background:#ff6961
}


.TodoList-Wrap .TodoList li:hover {
  border: 1px solid#333
}


.TodoList-Wrap .TodoList .Delete {
  background: #dc3545;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  width: 72px;
}
.TodoList-Wrap .TodoList .Delete:hover {
  background: #aa2a37;
}
.ViewToggle{
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  padding: 5px 5px 20px 0px;
}
.ViewToggle .ViewChange{
  padding: 0;
  background: none;
  color: #005abb; 
}


.ViewToggle .ViewChange:hover{
background: none;
text-decoration: underline;
}

.TodoList-Wrap .TodoList .TodoDetails{
  width: 100%;
}
.TodoList-Wrap .TodoList .TodoDetails div{
  border: 1px solid#ccc;
  width: auto;  
  padding: 10px;
  margin: 5px;
}
/* 모달 스타일 */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5)!important; /* 배경에 어둡게 처리 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* 다른 요소 위에 표시 */
}

/* 모달 내용 스타일 */
.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  max-width: 80%; /* 모달 최대 너비 조절 */
  color: #9698A6;
}

.btnwrap {
  display: flex;
  justify-content:center;
  margin-top: 10px;
}
.btnwrap .Delete{
  margin-right: 16px;
}

/* 모달 닫기 버튼 스타일 */
.modal-content button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.CalendarView{
  width: 80%;
}