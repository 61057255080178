.WebGame-Wrap {
  padding: 1rem;
  padding-top: 5rem;
  border-radius: 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100vw;
  justify-content: flex-start;

}


  .WebGame-Wrap canvas {
    border: 1px solid black;
    width: 100%;
    max-width: 400px;
    height: auto;
    touch-action: none;
  }

  .lightmode .WebGame-Wrap .Title{color: #333;}

  .WebGame-Wrap .Title{
    text-align: center;
    color: #fff;
    margin-bottom: 1.5rem;
    user-zoom: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    font-size: 20px;
  }

  .WebGame-Wrap button {
    margin: 5px;
    padding: 10px 20px;
    font-size: 16px;
    
    background: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s;
    margin: 1rem;
    
  }
  .WebGame-Wrap button:hover {
      background: #0056b3;
  }
  
  .WebGame-Wrap .highscores {
    padding: 1rem;
    width: 100%;
    height: 300px;
    overflow: auto;
  }

  .WebGame-Wrap .highscores h2{color: #fff}
  .lightmode .WebGame-Wrap .highscores h2{color: #333}

  .WebGame-Wrap .highscores ol{
    margin-top: 1rem;
    padding-left: 2rem;
    width: 100%;
  }

  .WebGame-Wrap .highscores li{
    padding: 0.5rem;
    width: 100%;
  }
  .WebGame-Wrap .highscores li:nth-child(odd){
    background: rgba(0, 0, 0, 0.1);
  }
  .WebGame-Wrap .info {
    margin-bottom: 20px;
  }
  
@media (max-width: 1024px) {

}

@media (max-width: 768px) {

}

@media (max-width: 664px) {

}

@media (max-width: 480px) {
  .WebGame-Wrap .highscores {
    height:100px;
  }
  .WebGame-Wrap .info {
    margin-bottom: 5px;
}
.WebGame-Wrap .info p {position: absolute; top:9rem;right: 2rem;}

}

@media (orientation: portrait) {
  @media (min-width: 768px) {

  }
}