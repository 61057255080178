/* 전체 컨테이너 스타일 */
.Am-container {
    display: flex;
    height: 100vh;
    width: 100%;
    overflow-y: hidden;
}

.VisualizeFinancialDataWrap{
    padding: 1rem;
    overflow-x: hidden;
}
.FinancialReportsWrap{
    padding: 1rem;
}
.Am-content {
    flex-grow: 1;
    overflow-y: auto;
}

/* 헤더 스타일 */
.Am-content h2 {
    margin-bottom: 1rem;
}

.tradeRecords {
    max-width: 100%;
    width: 100%;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow-x: hidden;
    height: 100%;
}
.Am-container .recharts-legend-wrapper{
}


/* 폼 스타일 */
.tradeForm {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-bottom: 1rem;
    justify-content: space-between;
    max-width: 100%;
    padding: 5px;
}

.tradeForm input,
.tradeForm select {
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
}

.tradeForm button {
    padding: 5px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.tradeForm button:hover {
    background-color: #45a049;
}

/* 검색 및 필터 스타일 */
.search-filter-wrap {
    display: flex;
flex-direction: column;
    gap: 5px;
    margin-bottom: 1rem;
}

.search-filter-wrap input,
.search-filter-wrap select {
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    flex: 1;
    width: auto;
}

/* 거래 기록 목록 스타일 */
.tranction-container {
    height: 60%;
overflow-y: auto;
}

.transaction-wrap {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.transaction-wrap input,
.transaction-wrap select {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
}

.transaction-wrap button {
    padding: 8px;
    background-color: #2196F3;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}
.transaction-wrap button:nth-child(2) {
    padding: 8px;
    background-color: #6e6e6e;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}
.transaction-wrap button:hover {
    background-color: #1976D2;
}
.transaction-wrap button:nth-child(2):hover {
    background-color: red;
}

.transaction-wrap div {
    display: flex;
    gap: 5px;
    justify-content: space-between;
}

/* 총계 스타일 */
.totals {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 1rem;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.totals div {
    font-size: 16px;
    font-weight: bold;
}

@media (max-width: 1024px) {
    .tradeForm {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-bottom: 1rem;
        justify-content: space-between;
        max-width: 100%;
        padding: 5px;
    }

    .Am-container .edms-sidebar {
        width: 180px;
        padding: 15px;
    }

    .Am-container .sidebar-item {
        font-size: 0.8rem;
        padding: 8px;
        white-space: nowrap;
    }
}

@media (max-width: 768px) {
    .Am-container .edms-sidebar {
        width: 160px;
        padding: 5px;
    }

    .Am-container .sidebar-item {
        font-size: 0.8rem;
        padding: 6px;
    }

    .edms-container * {
        font-size: 12px;
    }
}

@media (max-width: 664px) {
    .Am-container {
        flex-direction: column;
    }

    .Am-container .edms-sidebar {
        width: 100%;
        height: auto;
        box-shadow: none;
    }

    .Am-container .sidebar-item {
        display: inline-block;
        width: calc(100% / 2 - 5px);
        margin-right: 5px;
        margin-bottom: 5px;
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .Am-container {
        height: auto;
    }

    .Am-container .edms-sidebar {
        width: 100%;
        height: auto;
        box-shadow: none;
    }

    .Am-container .sidebar-item {
        display: inline-block;
        width: calc(100% / 2 - 5px);
        margin-right: 5px;
        margin-bottom: 5px;
        font-size: 1rem;
    }
    .search-filter-wrap input,
.search-filter-wrap select {
    font-size: 1rem;
}
}

@media (orientation: portrait) {
    @media (min-width: 768px) {
        .Am-container {
            flex: row;
        }

        .Am-container .edms-sidebar {
            width: 20%;
            height: auto;
            box-shadow: none;
        }
    }
}