/* 전체 래퍼 스타일 */
.ERP-Wrap {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .contacts-container {
    padding: 20px;
    flex: 1;
    overflow-y: auto;
    width: 100%;
    text-align: center;
  }
  
  .contacts-list {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .contact-item {
    background: rgba(0,0,0,0.1);
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-item:hover {
    background-color: #f1f1f1;
  }
  
  .contact-item p {
    margin: 5px 0;
  }
  
  .contact-details {
    margin-top: 10px;
    border-top: 1px solid #ddd;
    padding-top: 10px;
  }
  
  .contact-details p {
    margin: 5px 0;
    font-weight: normal;
  }
  