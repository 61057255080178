/* Weather.css */
.Weather-Wrap {
    padding: 1rem;
    padding-top: 5rem;
    border-radius: 15px;   
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 100vh;
    width: 100vw;
    max-width: 1200px;
}

.darkmode .Weather-Wrap .Title {color: #fff;}
.Weather-Wrap .Title {
    text-align: center;
    margin-bottom: 1.5rem;
    user-zoom: none;
    user-select: none;
    -moz-user-select: none;
    /* Firefox 지원 */
    -webkit-user-drag: none;
    /* Webkit 브라우저 (Chrome, Safari) 지원 */
    -webkit-user-select: none;
    /* Webkit 브라우저 (Chrome, Safari) 지원 */
    font-size: 20px;
}
.lightmode.Weather-Wrap .Title {color: #333;}

.lightmode .Weather-Wrap select {color: #333;}
.Weather-Wrap select {
    width: 40%;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 5px;
    margin: 0.5rem 0 0.5rem 0;
    cursor: pointer;
    text-align: center;
    border: none;
    font-size: 2rem;
    background: none;
    color: #000000;
    size: 5;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
}
select option {
color: #333;
  }

  .forecast-row {
    display: flex;
    width: 80%;
    overflow-x: auto;
    justify-content: space-between;
    overflow-y: hidden;
    min-height: 115px;
    height: 20vh;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
  .lightmode .forecast-entry {
      box-shadow: 0 4px 8px rgba(0,0,0,0.1);
      background: rgba(0, 0, 0, 0.1);
    }
    .lightmode .modal-content {
    background: #fff;
    }
    
.forecast-col {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    width: 80%;
    overflow-y: auto;
    height: 50vh;
}


.forecast-entry {
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(30px);
    border-radius: 10px;
    padding: 0.5rem;
    width: 200px;
    text-align: center;
    display: flex;
    justify-content: space-around;
}
.forecast-row .forecast-entry {
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(30px);
    border-radius: 10px;
    padding: 0.5rem;
    width: 200px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}
.forecast-row .forecast-entry  .forecast_text {
    text-align: center;
    margin:  0;
    width: 10rem;
    white-space: nowrap;
    font-size: 1rem;
    overflow: auto;
}

.forecast-row .forecast-entry {
margin-right: 6px;
}

.forecast-col .forecast-entry {
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(30px);
    border-radius: 10px;
    margin-top: 1rem;
    padding: 1rem;
    width: 100%;
    text-align: left;
    white-space: nowrap;
}
.forecast-col :nth-child(1).forecast-entry {

    margin-top: 0;
}
.forecast-col .forecast-entry div {
    text-align: left;
}

.forecast-entry img {
    width: 3rem;
    height: 3rem;
    display: block;
    margin: auto;
}

.forecast_text {
    text-align: center;
    margin: 0.5rem 0;
    white-space: nowrap;
    font-size: 1rem;
}

/* modal */
.modal {
    display: flex;
    /* 수정: 화면 중앙에 표시하기 위해 flex로 설정 */
    justify-content: center;
    /* 수정: 가로 중앙 정렬 */
    align-items: center;
    /* 수정: 세로 중앙 정렬 */
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    color: #333;
}

.modal-content {
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(30px);
    padding: 20px;
    border: 1px solid #888;
    max-height: 80%;
    /* 수정: 모달 높이를 최대 80%로 제한 */
    overflow-y: auto;
    /* 수정: 필요할 때 스크롤바 표시 */
    width: 80%;
    border-radius: 10px;
    text-align: center;
    /* 수정: 내용 가운데 정렬 */
}

.lightmode .Weather-Wrap .modal h2 {color: #333;}
.Weather-Wrap .modal h2 {
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    margin: 15px;
}

.modal-content .modal-item {
    margin: 15px;
    border-bottom: 1px solid #888;
}

.modal-content .modal-item div{
    line-height: 30px;
    color: #9698A6;
}



.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    text-align: right;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.leaflet-top, .leaflet-bottom {
    position: absolute;
     z-index: 800 !important; 
    pointer-events: none;
}

.icon-clear-sky-day {
    color: #ffd700;
    /* 금색 */
}

.icon-clear-sky-night {
    color: #1e90ff;
    /* 밝은 파랑색 */
}

.icon-few-clouds-day {
    color: #f0e68c;
    /* 카키색 */
}

.icon-few-clouds-night {
    color: #add8e6;
    /* 연한 파랑색 */
}

.icon-scattered-clouds {
    color: #c0c0c0;
    /* 은색 */
}

.icon-broken-clouds {
    color: #808080;
    /* 회색 */
}

.icon-shower-rain {
    color: #00bfff;
    /* 딥 스카이 블루 */
}

.icon-rain-day {
    color: #1e90ff;
    /* 도저 블루 */
}

.icon-rain-night {
    color: #4682b4;
    /* 스틸 블루 */
}

.icon-thunderstorm {
    color: #ff4500;
    /* 오렌지 레드 */
}

.icon-snow {
    color: #fffafa;
    /* 스노우 화이트 */
}

.icon-mist {
    color: #f5f5f5;
    /* 화이트 스모크 */
}