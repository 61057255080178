 .Footer {
     display: flex;
     /* height: 100%; */
     width: 100%;
     border-top: 1px solid #fff;
     margin-top: 0;
     padding: 20px 16px 0px 16px;
 }

 .lightmode .Footer {
     border-top: 1px solid #333;
     background: #fff;
 }

 .modimg-wrap .Footer .title {
     font-size: 14px;
     margin: 0;
     padding: 0;
     line-height: 20px;
     padding-bottom: 5px;
     margin-bottom: 5px;
     color: #fff;
 }

 .light .title {
     color: #333;
 }

 .darkmode .title {
     color: #fff;
 }

 .Footer .subs {
     font-size: 12px;
     margin: 0;
     padding: 0;
     line-height: 20px;
     padding-bottom: 5px;
     margin-bottom: 5px;
     white-space: nowrap;
     color: inherit;
     text-decoration: none;
 }

 .Footer div {
     font-size: 12px;
     width: 100%;
     text-align: center;
     line-height: 25px;
 }

 .Footer .logoWrap {
     display: flex;
     flex-direction: column;
     align-items: center;

 }

 .Footer .logo {
     width: 124px;
     height: 35px;
     background: url("../images/LOGO.png");
 }

 .Footer .flx {
     display: flex;
     width: 124px;
 }


 .Footer .kakao {
     width: 25px;
     height: 25px;
     background: url("../images/kakao.png") no-repeat center center;
     background-size: contain;
     margin-left: 16px;
     border: 0;
     cursor: pointer;
 }

 .Footer .insta {
     width: 25px;
     height: 25px;
     background: url("../images/insta.png") no-repeat center center;
     background-size: contain;
     border: 0;
     cursor: pointer;
 }

 .Footer button:hover {
     background-color: rgba(255, 255, 255, 0.1)
 }

 /* 반응형 레이아웃 */
 @media (max-width: 768px) {}

 @media (max-width: 664px) {}


 @media (max-width: 480px) {
     .Footer {
         flex-direction: column;
         align-items: center;
     }

     .Footer .logoWrap {
         display: flex;
         flex-direction: row;
         justify-content: space-between;
     }

     .Footer .logo {
         margin-bottom: 0px;
     }

     .Footer .flx {
         margin: 0;
         display: flex;
         justify-content: center;
     }

     .Footer .subs {
         margin: 0;
     }

     @media (max-width: 480px) {
         .Footer .contactWrap {
             display: flex;
             flex-direction: row;
             justify-content: flex-start;
             justify-content: space-between;
         }
         .Footer .etcWrap {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            justify-content: space-between;
        }
         .Footer>div {
             display: flex;
             flex-direction: row;
             align-items: center;
         }
     }





     .Footer .title,
     .Footer .subs {
         width: auto;
         text-align: center;
     }

 }