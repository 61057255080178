
.container .Title {
  text-align: center;
  color: #fff;
  margin-bottom: 1.5rem;
  user-zoom: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  font-size: 20px;
}

.content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 70px 1fr 1fr;
  grid-gap: 16px;
}


.summary {
  grid-column: 1 / 3;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.summary .infos {
  display: flex;
  border: solid 1px #ccc;
  border-radius: 3px;
  width: 20%;
  text-align: center;
  font-size: 1rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  background: rgba(255,255,255,0.1);
  backdrop-filter: blur(30px);
}

.chart {
  padding: 10px;
  background: rgba(255,255,255,0.1);
  min-height: 530px;
  max-height: 40vh;
}
.chart .chart {
  background: none;
}

.GridBox3 {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  border-radius: 6px;
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); */
  height: 100%;
  width: 100%;
  gap: 10px;  
}



.GridBox3 .GridBox3-Item:nth-child(2) {}

.GridBox3 .GridBox3-Item:nth-child(3) {
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.chart3s {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30%;
}

.chart2s {
  display: grid;
  justify-content: space-between;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr;
  border-radius: 6px;
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); */
  height: 100%;
  width: 100%;
}

.chart2s_item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chart .userwrap {
  overflow-y: scroll;
  margin: auto;
  height: calc(100% - 50px);
  width: 100%;
}



.tabs {
  display: flex;
  margin-bottom: 20px;
  width: 100%;
}

.tab-button {
  flex: 1 1;
  padding: 10px 0px;
  font-size: 13px;
  font-weight: 600;
  border: none;
  background-color: transparent;
  cursor: pointer;
  white-space: nowrap;
  height: 30px;
}

.tab-button.active {
  text-decoration: underline #fff 2px;
  text-underline-offset: 5px;
}

.userwrap .mentions li {
  padding: 5px 0px 0px 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.2;
  margin-bottom: 10px;
}

.userwrap .mentions .postwrap {
  padding: 5px 5px 0px 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.2;
  margin-bottom: 10px;
  display: flex;
}

.userwrap .mentions .postwrap2 {
  padding: 5px 0px 0px 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 1.2;
  margin-bottom: 10px;

}

.vertical-text {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  white-space: nowrap;
  /* 필요에 따라 설정 */
  transform: rotate(-180deg);
  text-align: center;
}


.userwrap .mentions .postwrap .contents {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90%;
  padding: 0px 0px 0px 5px;
}

.flx_spbt {
  display: flex;
  justify-content: space-between;
    width: 100%;
}

.flx_spbt div span {
  padding-left: 5px;
}

.sidebar {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.sidebar .userwrap .mentions {
  padding: 10px;
  max-height: 91vh
}


/* modal */
/* 모달을 화면 전체에 가득 차게 만듭니다. */
.sidebarmenu {

  position: fixed;
  top: 0;
  right: 0;
  color: #333;
  background-color: transparent;
  z-index: 999;
  /* 다른 요소 위에 헤더 메뉴가 나타나도록 설정합니다. */
  transition: top 0.3s;
  /* 스크롤 시 부드럽게 이동하도록 설정합니다. */
  text-align: left;
  padding: 20px 20px 10px 10px;
  width: auto;
  border: none;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5);  */
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  /* 모달이 다른 요소 위에 표시되도록 설정합니다. */
}

/* 모달 내용을 스타일링합니다. */
.modal .modal-sidebar {
  background-color: #fff;
  /* 모달 내부 배경색 */
  border-radius: 10px;
  max-width: 90%;
  /* 모달 내용의 최대 너비를 설정합니다. */
  max-height: 70%;
  /* 모달 내용의 최대 높이를 설정합니다. */
  overflow-y: auto;
  /* 모달 내용이 내용보다 크면 스크롤 가능하도록 설정합니다. */
  box-shadow: 4px 5px 9px rgba(0, 0, 0, 0.2);
}


/* 탭 버튼 스타일 */
.modal .tab-button {
  background-color: transparent;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1em;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(30px);
  border-radius: 3px;
}

/* 활성화된 탭 버튼 스타일 */
.modal .tab-button.active {
  background-color: #007bff;
  /* 활성화된 탭 버튼 배경색 */
  color: #fff;
  /* 활성화된 탭 버튼 텍스트 색상 */
}

.modal .close-modal {
  position: absolute;
  top: 15px;
  left: 15px;
  background: transparent;
  border: none;
}

/* 모달 내용의 리스트 스타일링 */
.modal ul.mentions {
  list-style: none;
  padding: 0;
}

.modal ul.mentions li {
  margin-bottom: 10px;

  padding: 0px 5px 5px 5px;
}

/* modal */



.red {
  color: red;
}

.green {
  color: rgb(0, 196, 159);
}

/* 기본 데스크탑 화면 */
@media (min-width: 1025px) {
  .content {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 70px 1fr 1fr;
  }
}

/* 데스크탑(세로) 화면 */
@media (max-width: 1024px) and (orientation: portrait) {
  .content {
    grid-template-columns: 1fr;
    grid-template-rows: 70px repeat(2, 1fr);
  }

  .chart {
    .GridBox3 {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(2, 1fr);
    }

    .chart3s {
      flex-direction: column;
    }
  }
}

/* 갤럭시 Z 플립 화면 */
@media (max-width: 1080px) {
  /* 스타일 조정 */
}

/* 노트북 & 테블릿 가로 (해상도 1024px ~ 1279px)*/
@media all and (min-width:1024px) and (max-width:1279px) {
  /* 스타일 조정 */
}

/* 테블릿 가로 (해상도 768px ~ 1023px)*/
@media all and (min-width:768px) and (max-width:1023px) {
  /* 스타일 조정 */
}

/* 모바일 가로 & 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (min-width:480px) and (max-width:767px) {
  /* 스타일 조정 */
}

/* 모바일 세로 (해상도 ~ 479px)*/
@media all and (max-width:479px) {
  h2 {
    font-size: 0.8em;
  }

  .summary {
    padding: 0;
  }

  .infos p {
    font-size: 0.6em;
  }

  .tabs button {
    font-size: 0.6em;
  }
/* 
  .container {
    display: flex;
    flex-direction: column;
    grid-template-columns: none;
  } */

  .chart .chart3s {
    flex-direction: row;
  }

  .content {
    display: flex;
    flex-direction: column;
    grid-template-columns: 1fr;
    /* 차트 1개씩 나열 */
    grid-template-rows: 70px repeat(4, 1fr);
    /* 필요에 따라 row 조정 */
  }
.contents{
  padding-left: 5px;
}
  .postwrap .contents div:nth-child(2) {
    font-size: .7em;
  }

  .postwrap .contents div:nth-child(3) div {

    display: flex;
    white-space: nowrap;
    font-size: .7em;
    align-items: center; /* 수직 가운데 정렬 */
  }
  .postwrap .contents .icon {

    font-size: 1.3em;
  }

  .chart tspan {
    font-size: 0.6em;
  }

  .postwrap .sidebar {
    display: none;
  }
}

.container {
  padding: 1rem;
  padding-top: 50px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 100%;
  width: 100vw;
  max-width: 1200px;
}



.sidebarmenu {
  color: white;
  border: none;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  top: 61px;
  right: 20px;
  padding: 0;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-sidebar {
  background-color: white;
  width: 300px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

