/* 리셋 CSS */
@font-face {
  font-family: 'Noto Sans KR';
  src: local('Noto Sans KR'), url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR&display=swap) format('woff2');
  unicode-range: U+AC00-D7AF; /* 한글 유니코드 범위 */
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(https://fonts.googleapis.com/css2?family=Roboto&display=swap) format('woff2');
  unicode-range: U+0020-007F, U+00A0-00FF; /* 영어와 숫자의 유니코드 범위 */
}

html, body {
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  font-family: 'Roboto', 'Noto Sans KR';
  overflow-y: auto;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

ul {
  list-style-type: none;
}

button {
  color: #9698A6;
}

.darkmode h1, h2, h3 {
  color: #fff;
}
.lightmode h1, h2, h3 {
  color: #333;
}

/* 스크롤바 스타일 */
::-webkit-scrollbar {
  width: 6px;
  background: #fff;
  border-radius: 3px;
}

::-webkit-scrollbar:horizontal {
  height: 6px;
  background: #fff;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background: #707070;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #606060;
}

.Applight ::-webkit-scrollbar {
  width: 6px;
  background: #e8e8e8;
  border-radius: 3px;
}

.Applight ::-webkit-scrollbar:horizontal {
  height: 6px;
  background: #e8e8e8;
  border-radius: 3px;
}

.Applight ::-webkit-scrollbar-thumb {
  background: #707070;
  border-radius: 3px;
}

.Applight ::-webkit-scrollbar-thumb:hover {
  background: #606060;
}

/* 링크 스타일 */
a {
  color: #007BFF;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  align-items: center;
}

.MainPage {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

.Footer {
  border-top: 1px solid #333;
  background: #0f1113;
  color: #fff;
  text-align: center;
  padding: 10px;
}

/* 다크 모드와 라이트 모드 */
.darkmode {
  background: #202429;
  color: #9698A6;
}

.lightmode {
  color: #333;
}

.lightmode h3 {
  color: #000;
}

.darkmode h3 {
  color: #f3f3f3;
}

.lightmode a {
  color: #007BFF;
}

.darkmode a {
  color: #4A90E2;
}
.lightmode .Item,
.lightmode .Introduce{
  border: 1px solid #333;
}
.Mod_Btn_Wrap {
  position: fixed;
  display: flex;
  justify-content: space-between;
  top: 90vh;
  right: 2rem;
  width: 5rem;
  z-index: 20;
  text-align: center;
}

.Mod_Btn_Wrap .material-symbols-outlined {
  line-height: 1rem;
  text-align: center;

  font-size: 1rem;
  height: 2rem;
  width: 2rem;
  border-radius: 16px;
  z-index: 30;
  background: #007bff;
    color: #fff;
    transition: background .3s ease;
    border: none;
}

.Mod_Btn_Wrap .material-symbols-outlined:hover {
  background: #0056b3;
  transition: background .3s ease;
}

.Mod_Menu {
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 10vh;
  right: 1rem;
  background: #f3f3f3;
  width: 250px;
  height: 150px;
  color: #333;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 15px;
}

.Mod_Menu .mod-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20%;
  width: 100%;
  height: 100%;
}

.Mod_Menu .mod-wrap .modimg-wrap {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.Mod_Menu .mod-wrap .modimg-wrap span{background: #f3f3f3;}

.Mod_Menu .mod-wrap .modimg-wrap .modimg  {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 50%;
padding: 10px;
}
.Mod_Menu .mod-wrap .modimg-wrap:nth-child(2) {background:#202429;}
.Mod_Menu .mod-wrap .modimg-wrap:nth-child(1) .modimg:nth-child(1) :nth-child(1),
.Mod_Menu .mod-wrap .modimg-wrap:nth-child(2) .modimg:nth-child(1) :nth-child(1) {
  background:#3A67EA;
  width: 100%;
  height: 5px;
  padding-bottom: 2px;
}
.Mod_Menu .mod-wrap .modimg-wrap:nth-child(1) .modimg:nth-child(1) :nth-child(2){
  background:#000;
  width: 60%;
  height: 5px;
  padding-bottom: 2px;
}
.Mod_Menu .mod-wrap .modimg-wrap:nth-child(1) .modimg:nth-child(1) :nth-child(3){
  background:#D3D5D7;
  width: 80%;
  height: 5px;
  padding-bottom: 2px;
}
.Mod_Menu .mod-wrap .modimg-wrap:nth-child(2) .modimg:nth-child(1) :nth-child(2){
  background:#f3f3f3;
  width: 60%;
  height: 5px;
  padding-bottom: 2px;
}
.Mod_Menu .mod-wrap .modimg-wrap:nth-child(2) .modimg:nth-child(1) :nth-child(3){
  background:#747474;
  width: 80%;
  height: 5px;
  padding-bottom: 2px;
}

.Mod_Menu .mod-wrap .modimg-wrap .modimg div {
  border-radius: 2px;
}

.modimg-wrap.selected {
  outline: 2px solid rgb(5, 158, 0);
  transition: outline .3s ease;
}

/* 반응형 레이아웃 */
@media (max-width: 768px) {
  .Title, .ItemTitle {
    font-size: 20px;

  }
  
}

@media (max-width: 1024px) {
  .Mod_Btn_Wrap {
    top: 80vh;
  
  }
  

  .Mod_Menu {
    bottom: 20vh;
  }
}
@media (max-width: 480px) {
  .Mod_Btn_Wrap {
      top: 80vh;
  }
}