/* Main.css */
:root {
    --main-color: #5c67f2;
    --card-bg-color: rgba(255, 255, 255, 0.1);
    --btn-hover-color: rgba(255, 255, 255, 0.1);

    color: #9698A6;
}


.scroll {
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 10;
}

.lightmode .ScrollEvent {
    /* background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(87, 87, 87, 1) 43%, rgba(0, 0, 0, 1) 100%); */
    background: #fff;
    }
    .lightmode .ScrollEvent p{color: #000;}
    .lightmode .ScrollEvent h2,.lightmode .ScrollEvent .highlight
    {
        color: rgb(21, 26, 176);
    }
.ScrollEvent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 3800px;
    z-index: 10;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(87, 87, 87, 1) 57%, rgba(164, 164, 164, 1) 100%);
    width: 100vw;
    align-items: center;
    padding: 30px;
}
.ScrollEvent .icon2{
    width: 100%;
    text-align: center;
    font-size: 3rem;
    animation: bounce 2s infinite;
}
.scroll-event-wrapper .Mod_Btn_Wrap {
    display: none;
}

/* 공통 스타일 */
.introSection {
    padding: 20px;
    margin: 10px 0;
    border-radius: 10px;
    color: #fff;
}

/* 제목 스타일 */
.introSection h2 {
    color: #007bff;
    margin-bottom: 10px;
    font-size: 1.5rem;
    white-space: nowrap;
}

.introSection .subs {
    background: rgba(255, 255, 255, 0.06);
    backdrop-filter: blur(30px);
    border-radius: 1rem;
    padding: 1rem;
    margin: 15%;
}

.lightmode .introSection .subs {
    background: rgba(190, 190, 190, 0.06);
}
.introSection .start {
    width: 100%;
    text-align: center;
}

.introSection h1 {
    font-size: 2rem;

    white-space: nowrap;
}

/* 내용 스타일 */
.introSection p {
    font-size: 1rem;
    line-height: 1.6;
}

/* 하이라이트 스타일 */
.highlight {
    font-weight: bold;
    color: #007bff;
    font-size: 2.5rem;
}


/* 애니메이션 적용 */
.scroll .introSection:nth-child(odd) {
    animation: slideInFromLeft 1s ease-out;
}

.scroll .introSection:nth-child(even) {
    animation: slideInFromRight 1s ease-out;
}



/* 메인 */
.Main-Wrap {
    padding: 1rem;
    padding-top: 50px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 100%;
    width: 100vw;
    max-width: 1200px;
}

.Main-Wrap .top {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 200px 200px;
    width: 100%;
    gap: 1rem;
    padding-bottom: 1rem;
    padding-top: 3rem;
    position: relative;
}

.Main-Wrap .top .bar {
    grid-row-start: 2;
    position: absolute;
    width: 30%;
    padding: 0 0 0 1rem;
    height: calc(100% - 1rem);

}


.Main-Wrap .Item {
    margin-top: 0;
    border-radius: 10px;
}

.Main-Wrap .top:nth-child(1) .Item {
    display: flex;
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    background: none;
    padding: 10px;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
}

.Main-Wrap .top .bgimg {
    background: url("../images/bgimg2.png");
    background-image: no-repeat;
    background-size: cover;
    background-position: center;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 3;
}

.Main-Wrap .top h2 {
    color: #333;
    text-align: center;
}

.WithBtn .ItemTitle {
    text-align: center;
}

/* .ticketicon {
    background: url("../images/ticket.png");
}

.webgameicon {
    background: url("../images/game.png");
}

.weathericon {
    background: url("../images/weather.png");
}

.pokedicicon {
    background: url("../images/pokedic2.png");
}

.erpicon {
    background: url("../images/erpicon.png");
} */


/* .Item a {
    background-image: no-repeat;
    background-size: cover;
    background-position: center;
    width: 150px;
    height: 150px;
    margin: 1rem 0 1rem 0;
    border: none;
    background-color: #fff;
}

.Item a:hover {
    outline: 1px solid #fff;
    background-color: #2e3cf8;
} */

.noScroll {
    overflow: hidden !important;
    /* width: 100%; */
    display: flex;
    justify-content: center;
}
.WithBtn {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.WithBtn:hover {
    outline: none;
}
.WithBtn .ItemContents{color: #333;}
.darkmode .WithBtn .ItemContents{color: #9698A6;}

.Main-Wrap .Items_Wrap {
    grid-gap: 1rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    padding-top: 1rem;
}

.lightmode .Main-Wrap .bar {background: none;}
.lightmode .Main-Wrap .Introduce {background: none;}
.Main-Wrap .Introduce::-webkit-scrollbar {
    background: inherit;
}
.Main-Wrap .Introduce {
    grid-column: 1;
    grid-column-end: 4;
    height: 250px;
    overflow-y: auto;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(30px);
    padding: 1rem;
    border-radius: 10px;
}

.Main-Wrap .ItemGroup {
    display: flex;
    gap: 1rem;
    grid-column-start: 1;
    grid-column-end: 4;
}

.Main-Wrap .ItemGroup .Item {
    width: 100%;
}


.Main-Wrap .ItemGroup .Item .Certifications-Wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 3rem);
}

.Main-Wrap .ItemGroup .Item .Certifications {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 1.5rem;
}

.Main-Wrap .ItemGroup .Item .Certifications .certifi_name {
    white-space: nowrap;
}

.lightmode .Main-Wrap .Item {background: none;}
.Main-Wrap .Item {
    background: rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    padding: 1rem;
}


.flx {
    display: flex;
    align-items: center;
    position: relative;
    height: 3rem;
}

.Main-Wrap .Item .flx .icon1 {
    position: absolute;
    color: #5c67f2;
    font-size: 2rem;
    width: 3rem;
    height: 3rem;
    text-align: center;
    left: 0;
    line-height: 3rem;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 3rem;
}
.lightmode .Main-Wrap .Item .flx .icon1 {

    background: none;
}

.Main-Wrap .Item .flx .ItemTitle {
    width: 100%;
    position: absolute;
    left: 0;
    text-align: left;
    padding-left: 3rem;
}
.SkillsWrap{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap; 
    gap: 20px; 
}
/* .SkillsWrap .skills{
    display: flex;
    width: 110px;
    padding: 3px;
    margin: 0 6px 0 6px;
    align-items: center;
    color: #333;
    border-radius: 12px;
    justify-content: space-between;
    cursor: pointer;
} */


/* 버튼 효과 */
.SkillsWrap .skills {
    display: flex;
    width: 110px;
    padding: 3px;
    margin: 0 6px;
    align-items: center;
    justify-content: space-between;
    color: #333;
    border-radius: 12px;
    cursor: pointer;
    background: linear-gradient(90deg, #f8f9fa, #e9ecef); /* 은은한 기본 배경 */
    border: 2px solid transparent; /* 기본 테두리 */
    transition: all 0.4s ease; /* 부드러운 모든 효과 전환 */
    position: relative;
    z-index: 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 기본 그림자 */
}

.SkillsWrap .skills:before {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;
    border-radius: 15px;
    background: linear-gradient(45deg, #ff6ec4, #7873f5);
    z-index: -1;
    opacity: 0;
    transition: opacity 0.4s ease;
}

.SkillsWrap .skills:hover:before {
    opacity: 1; /* 빛나는 테두리가 나타남 */
}

.SkillsWrap .skills:hover {
    background: linear-gradient(90deg, #7873f5, #ff6ec4); /* 호버 시 배경색 강조 */
    color: #fff; /* 텍스트 색상 반전 */
    transform: scale(1.1); /* 살짝 커짐 */
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); /* 그림자 강조 */
}
.skills svg {
    width: 1rem;
    height: 1rem;
  }

.SkillsWrap .skills:active {
    transform: scale(0.95); /* 클릭 시 약간 작아짐 */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); /* 그림자 감소 */
}
/* 버튼 효과 */




.SkillsWrap :nth-child(1).skills{
background: #E34F26;}
.SkillsWrap :nth-child(2).skills{
background: #1572B6;}
.SkillsWrap :nth-child(3).skills{
background: #61DAFB;}
.SkillsWrap :nth-child(4).skills{
background: #00599C;}
.SkillsWrap :nth-child(5).skills{
background: #777272;}
.SkillsWrap :nth-child(6).skills{
background: #DD2C00;}
.SkillsWrap .skills span{
    padding: 0 1px 0 1px;
    user-select: none;
}
.SkillsWrap .skills button{
    border: none; 
    background: none; 
    color: #333;
    border-left: 2px solid #333;
cursor: pointer;}
.SkillsWrap .skills button:hover{color: #007bff;}
.SkillsWrap .skills .skillsIcon{
    font-size: 1rem;
    user-select: none;
}







.lightmode .Main-Wrap .Item:hover {
    outline: 1px solid #333;
}

.darkmode .Main-Wrap .Item:hover {
    outline: 1px solid #fff;
}

.Main-Wrap .Items_Wrap .Link-Wrap {
    grid-column-start: 1;
    grid-column-end: 4;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
}

.hamberger {
}

.lightmode .bar {
    background: #f3f3f3;
}
.bar {
    background: rgba(0, 0, 0, 0.1);
    width: 100%;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    padding: 1rem;
    border-radius: 10px;
    border: 1px solid #333;
}




/* 애니메이션 정의 */
@keyframes bounce {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-20px);
    }
}

@keyframes slideInFromLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInFromRight {
    from {
        transform: translateX(100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}


/* 반응형 레이아웃 */
@media (max-width: 1024px) {
    .Main-Wrap {
        padding: 0.5rem;
    }

    .Main-Wrap .top {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 200px;
        gap: 0.5rem;
    }

    .Main-Wrap .top .bar {
        grid-row-start: inherit;
        grid-column-start: 1;
        grid-column-end: 3;
        position: inherit;
        width: 100%;
        padding: 1rem;
    }


    .Main-Wrap .Items_Wrap {
        grid-template-columns: repeat(2, 1fr);
        gap: 0.5rem;
    }

    .Main-Wrap .Introduce {
        height: 200px;
    }
    .Main-Wrap .Items_Wrap .Link-Wrap {
        grid-column-start: 1;
        grid-column-end: 4;
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(4, 1fr);
        width: 100%;
    }
}

@media (max-width: 768px) {
    .Main-Wrap {
        padding: 0.5rem;
    }

    .Main-Wrap .top {
        grid-template-columns: 1fr;
        grid-template-rows: 200px;
        gap: 0.5rem;
    }

    .Main-Wrap .Items_Wrap {
        grid-template-columns: 1fr;
        gap: 0.5rem;
    }

    .Main-Wrap .Introduce {
        height: 150px;
        overflow-y: auto;
    }

    .ItemGroup {
        display: flex;
        flex-direction: column;
    }

    .Main-Wrap .ItemGroup .Item {
        width: 100%;
    }
    .Main-Wrap .Items_Wrap .Link-Wrap {
        grid-column-start: 1;
        grid-column-end: 4;
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(3, 1fr);
        width: 100%;
    }
}

@media (max-width: 664px) {
    .Main-Wrap {
        padding: 0.5rem;
    }

    .Main-Wrap .top {
        grid-template-columns: 1fr;
        grid-template-rows: 200px;
        gap: 0.5rem;
    }

    .Main-Wrap .Items_Wrap {
        grid-template-columns: 1fr;
        gap: 0.5rem;
    }

    .Main-Wrap .Introduce {
        height: 150px;
        overflow-y: auto;
    }

    .Introduce .ItemContents,
    .Item .ItemContents,
    .certifi_name,
    .certifi_day {
        font-size: 0.9rem;
    }

    .Item h3 {
        font-size: 1.2rem;
    }

    .ItemGroup {
        display: flex;
        flex-direction: column;
    }

    .Main-Wrap .ItemGroup .Item {
        width: 100%;
    }
    .Main-Wrap .Items_Wrap .Link-Wrap {
        grid-column-start: 1;
        grid-column-end: 4;
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
    }
}

@media (max-width: 480px) {
    .Main-Wrap {
        padding: 0.5rem;
    }

    .Main-Wrap .top {
        grid-template-columns: 1fr;
        grid-template-rows: 200px;
        gap: 0.5rem;
    }

    .Main-Wrap .Items_Wrap {
        grid-template-columns: 1fr;
        gap: 0.5rem;
    }

    .Main-Wrap .Introduce {
        height: 150px;
        overflow-y: auto;
    }

    .Introduce .ItemContents,
    .Item .ItemContents,
    .certifi_name,
    .certifi_day {
        font-size: 0.8rem;
    }

    .Item h3 {
        font-size: 1rem;
    }

    .ItemGroup {
        display: flex;
        flex-direction: column;
    }

    .Main-Wrap .ItemGroup .Item {
        width: 100%;
    }


}

@media (orientation: portrait) {
    @media (min-width: 768px) {
        .Main-Wrap {
            padding: 1rem;
      /* height: calc(100vh - 8rem); */
        }


        .Main-Wrap .Items_Wrap {
            grid-template-columns: repeat(2, 1fr);
            gap: 1rem;
            grid-template-rows: 1fr 1fr;
            height: 55%;
        }

        .Main-Wrap .Introduce {
           height: 100%
        }
    }
}