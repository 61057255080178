.ERP-Wrap {
  padding: 1rem 1rem 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: calc(100vh - 120px);
  }
  .ERP-Wrap h2{color: #fff;}
  .darkmode .Notices h2{color: #fff;}
  .Notices {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .Notices button{    background: #007bff;
    color: #fff;
  padding: 5px;
cursor: pointer;}
  .Notices button:hover{background: #0056b3;
    transition: background 0.3s ease;}
  
  .ERP-Wrap .Header {
    display: flex;
    position: relative;
    width: 100%;
    justify-content: space-between;
    height: 50px;
  }
  .ERP-Wrap .Header .module{
    cursor: pointer;
    font-size: 1.3rem;}

  .ERP-Wrap .Header .module:hover{
    color: #0056b3;
    transition: color .3s ease;
  }
  .ERP-Wrap .Header .company-name{
    position: absolute;
    top: 1rem;
    font-size: 2rem;
    top: 1.5rem;
    line-height: 0rem;
  }
 
  .ERP-Wrap .Header .hamberger:hover {
    color: #0056b3;
    transition: color .3s ease;
  }
  .ERP-Wrap .Header .hamberger {
    z-index: 800;
    display: block;
    cursor: pointer;
  }
  
  .ERP-Wrap .Header .topNav a {
    font-size: 1.2rem;
  }
  
  .ERP-Wrap .Notices {
    padding: 0 20px 0 20px;
  }
  
  .ERP-Wrap .Main-Content {
    display: grid;
    grid-template-columns: minmax(250px, 1fr) minmax(700px, 5fr);
    width: 100%;
    height: calc(100% - 50px);
  }
  
  .ERP-Wrap .Module_Wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .ERP-Wrap .L_SideBar {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 1rem;
    padding-top: 3rem;
    }
    .ERP-Wrap .L_SideBar :nth-child(1).Item-Wrap{
      height: 100%;
      }
      .ERP-Wrap .L_SideBar :nth-child(2).Item-Wrap{
        position: relative;
        }
        .ERP-Wrap .L_SideBar :nth-child(2).Item-Wrap select{
          width: 100%;
        }
        .ERP-Wrap .L_SideBar :nth-child(3).Item-Wrap{
          height: 20%;
          align-items: center;
          justify-content: center;
          }
          .ERP-Wrap .L_SideBar .rbc-event-content{
              font-size: 0.7rem;
          }
          
  .ERP-Wrap .modal-content{
    max-width: 600px;
  }
  .ERP-Wrap .modal-content p{
    color: #fff;
  }
  .darkmode .ERP-Wrap .L_SideBar .Item-Wrap {color: #333;}
  .ERP-Wrap .L_SideBar .Item-Wrap {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 50%;
    border: 1px solid #ccc;
    padding: 1rem;
    border-radius: 8px;
    background: #f9f9f9;  }
  .frequent-contacts-list {
    margin-bottom: 3px;
    height: 30%;
    overflow-y: auto;
    width: 100%;
  }
  
  .frequent-contact {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 5px;
  }
  .frequent-contacts-list .selected {
    background: rgba(0, 0, 0, 0.1) !important;
  }
  .action-buttons {
    display: flex;
    align-items: flex-end;
    position: absolute;
    flex-direction: column;
    right: 0;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(30px);
  }
  
  .action-buttons button {
    margin: 0.1rem;
    padding: 3px 6px;
    font-size: 12px;
    cursor: pointer;
    color: #333;
    -webkit-user-select: none; 
    -moz-user-select: none;   
    -ms-user-select: none;    
    user-select: none;   
  }
  .action-buttons button:hover {
    background: #0056b3;
    color: #fff;
  }
  .ERP-Wrap .usermenu {
    font-size: 1rem;
    color: #333;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background: rgba(255, 255, 255, 0.1);
  
  }
  
  .ERP-Wrap .Item-Wrap .Title {
    color: #c23b35;
    text-align: left;
    padding-bottom: 1rem;
    font-size: 16px;
  }
  
  .ERP-Wrap .Item-Wrap .UserInfo {
    height: 100%;
  }
  
  .ERP-Wrap .Item-Wrap .UserInfo .UserName {
    display: flex;
    font-weight: bold;
    margin-bottom: 0.5rem;
    /* width: 100%; */
    flex-direction: row;
    justify-content: space-between;
    color: #333;
  }
  
  .ERP-Wrap .Item-Wrap .UserInfo .more:hover {background: #0056b3;
  transition: background .3s ease;}
  .ERP-Wrap .Item-Wrap .UserInfo .more {
    width: 1.5rem;
    height: 1.5rem;
    text-align: center;
    line-height: 1.2rem;
    cursor: pointer;
    padding: 1px;
    background: #007bff;
    color: #fff;
    transition: background .3s ease;
  }
  
  .ERP-Wrap .Item-Wrap .user-list {
    height: 100%;
    overflow-y: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background: #fff;
  }
  .ERP-Wrap .Schedule{
    display: flex;
    justify-content: center;
  }
  .ERP-Wrap .Schedule .Schedule_Add{
  width: 100%;
  }
  
  .ERP-Wrap .Item-Wrap .user-list .user:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  
  .ERP-Wrap .Item-Wrap .user-list .user {
    cursor: pointer;
    margin: 5px;
    padding: 5px;
    border-radius: 4px;
  }
  
  .ERP-Wrap .Item-Wrap .frequent-contact {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    background: none;
    cursor: pointer;
    margin: 5px 0;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid transparent;
  }
  .ERP-Wrap .Item-Wrap .frequent-contact span {
  font-size: 15px;
  position: relative;
  }
  .ERP-Wrap .Item-Wrap .frequent-contact:hover {
    border: 1px solid  #007bff;;
    transition: border .3s ease;
  }
  
  .ERP-Wrap .Item-Wrap .frequent-contact .dropdown-menu,
  .ERP-Wrap .Item-Wrap .frequent-contact .dropdown-menu div {
    border: 1px solid #ccc;
    cursor: pointer;
    padding: 5px;
    background: #fff;
  }
  
  .ERP-Wrap .Item-Wrap .frequent-contact .dropdown-menu div:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  
  .ERP-Wrap .Item-Wrap .menu button {
    text-align: center;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background: none;
    border: none;
    color: #333;
    padding: 10px;
    width: 100%;
    cursor: pointer;
  }
  
  .ERP-Wrap .Modules {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 0.4rem;
    padding: 20px 20px 0 20px;
    overflow-y: auto;
    width: 100%;
    justify-items: center;
    height: calc(100vh - 14rem);
  }
  
  .darkmode .ERP-Wrap .Modules .module {color: #333;}
  .ERP-Wrap .Modules .module {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 150px; */
    text-align: center;
    border: 1px solid #333;
    position: relative;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .ERP-Wrap .Modules .module:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  .darkmode .ERP-Wrap .Modules .module:hover {
    color: #fff;
  }
  .ERP-Wrap .Modules .material-symbols-outlined {
    font-size: 4rem;
  }
  
  .ERP-Wrap .Modules .module .alarm {
    font-size: 1rem;
    background: red;
    color: #fff;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  
  .ERPLogin .signup-modal {
    position: absolute;
    height: 50%;
    width: 50%;
  }
  
@media (max-width: 1024px) {
  .ERP-Wrap .Modules {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 768px) {
  .ERP-Wrap .Main-Content {
    grid-template-columns: minmax(250px, 1fr) minmax(400px, 5fr);
}

  .ERP-Wrap .Modules {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 664px) {
  .ERP-Wrap .Modules {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .Notices{
    padding: 0rem 1rem 1rem 1rem;
  }
  .Title{
    font-size: 1rem;
    width: auto;
  }
  .ERP-Wrap .Modules {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    height: calc(80vh - 100px);
    overflow-y: hidden;
    padding: 0;
    height: 100%;
  }
  .ERP-Wrap .Modules .module{
    width: 100%;
  }
  .ERP-Wrap .Main-Content {
    grid-template-columns: 1fr;
    gap: 1rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .ERP-Wrap .L_SideBar {
    width: 100%;
    height: auto;
  }
  .ERP-Wrap .L_SideBar .Item-Wrap:nth-child(1) {
    height: 350px;
    }
    .ERP-Wrap .L_SideBar .Item-Wrap:nth-child(2) {
      height: 200px;
      align-items: center;
      }
      .ERP-Wrap .L_SideBar .Item-Wrap:nth-child(3) {
        height: auto;
        }


  .ERP-Wrap .topNav {
    display: block;
    position: absolute;
    right: 1rem;
    text-align: right;
    width: auto;
    top: 3rem;
    background: rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    text-align: left;
    color: #333;
    z-index: 999;
  }
  .topNav .module{

    border: 1px solid #333;
    color: #fff;
  }
  .ERP-Wrap .Item-Wrap .user-list {
    height: auto;
    max-height: 200px;
  }
  .ERP-Wrap .Item-Wrap .UserInfo .UserName {
    font-size: 1rem;
  }
  .ERP-Wrap .Header .company-name {
    font-size: 1.5rem;
  }
}

@media (orientation: portrait) {
  @media (min-width: 768px) {
    .ERP-Wrap .Modules {
      grid-template-columns: repeat(4, 1fr);
    }
    .ERP-Wrap .Main-Content {
      display: grid;
      grid-template-columns: minmax(100px, 2fr) minmax(250px, 8fr);
      width: 100%;
      height: calc(100% - 50px);
    }
    .ERP-Wrap .L_SideBar {
      display: flex;
      height: 100%;
    }
  }
}