/* 전체 래퍼 스타일 */
.ERP-Wrap {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: calc(100vh - 120px);
}

.Messenger-Wrap {
  display: flex;
  flex: 1;
  overflow: hidden;
  width: 100%;
}

.user-list {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.user-list.hidden {
  display: none;
}

.user {
  padding: 1rem;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.user:hover {
  background-color: #e9e9e9;
}

.lastMsg {
  color: #999;
  font-size: 12px;
}

.Messages-Wrap {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.Messages-Wrap.hidden {
  display: none;
}

.Messages-Wrap .header {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #ddd;
  background: rgba(0, 0, 0, 0.1);
}

.back-button:hover {
  background-color: white;
  color: black;
  outline: 2px solid #3A67EA;
}
.back-button {
  background-color: #3A67EA;
  border: none;
  color: white;
  padding: 0.5rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 12px;
  transition-duration: 0.4s;
  margin-right: 1rem;
}

.selected-user {
  font-weight: bold;
}

.Msg {
  flex: 1;
  padding: 1rem;
  overflow-y: auto;
}

.sending-wrap {
  display: flex;
  padding: 1rem;
  border-top: 1px solid #ddd;
}

.sending-wrap input {
  flex: 1;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-right: 1rem;
}

.sending-wrap button {
  padding: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.sending-wrap button:hover {
  background-color: #0056b3;
}

.message-container {
  margin-bottom: 1rem;
}

.my-message {
  text-align: right;
}

.other-message {
  text-align: left;
}
