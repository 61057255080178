/* 로그인 페이지 전체 배경 */
.ERP_Login_Container{
  padding: 1rem;
  padding-top: 5rem;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 100vh;
  width: 100vw;
  max-width: 1200px;
}
    .darkmode .ERP_Login_Container h2{color: #fff;}
    .ERP_Login_Container .Title{
      text-align: center;
      margin-bottom: 1.5rem;
      user-zoom: none;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-drag: none;
      -webkit-user-select: none;
      font-size: 20px;
}
.erplogin_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #121212; 
  }
  
  /* 로그인 폼 스타일링 */

  form {
    background: rgba(0, 0, 0, 0.5); 
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
    max-width: 400px;
    width: 100%;
    margin: 20px 0;
    backdrop-filter: blur(10px) brightness(120%) contrast(90%); 
  }
.lightmode .ERP_Login_Container form{
    background: none;
}
  .ERP_Login_Container .sign{
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
    margin: 20px 0;
  }
  /* 입력 필드 스타일링 */
  .ERP_Login_Container form input[type="email"],
  .ERP_Login_Container form input[type="password"] {
    border-radius: 4px;
    color: #333;
    margin: 8px 0;
    padding: 3px;
    width: 100%;
  }
  .ERP_Login_Container form input[type="email"]:focus,
  .ERP_Login_Container form input[type="password"]:focus {
    
    outline:2px solid #0056b3;
  transition: outline 0.3s ease;}
  
  /* 버튼 스타일링 */
  .ERP_Login_Container button {
    width: 100%;
    padding: 12px;
    background: #007bff;
    color: white; 
    border: none;
    border-radius: 4px; 
    cursor: pointer;
    transition: background 0.3s; 
  }
  
  button:hover {
    background: #0056b3; 
  }
  
  /* 오류 메시지 스타일 */
  .ERP_Login_Container p {
    text-align: center;
    color: #ff686b; 
  }

   .ModalBackdrop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
   }
  .ERP-SignupModal{
    position: absolute;
    z-index: 101;
    background: rgba(255, 255, 255, 0.5);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;
    margin: 20px 0;
    backdrop-filter: blur(10px) brightness(120%) contrast(90%);
    height: 50vh;
    overflow-y: scroll;
  }
  .darkmode .ERP-SignupModal h2,
  .darkmode .ERP-SignupModal label{
    color: #fff;}

  .ERP-SignupModal form div input{
    border-radius: 4px;
    color: #333;
    margin: 8px 0;
    padding: 3px 6px;
    width: 100%;
  }
  .ERP-SignupModal form div input:focus{    
    outline:2px solid #0056b3;
  transition: outline 0.3s ease;}
  .ERP-SignupModal button{
margin-top: 1rem;
  }
  