.ERP-Wrap .calendar-Wrap {
    width: 100%;
}


.ERP-Wrap .calendar-Wrap .slide-left {
    animation: slideInFromBottom 0.3s ease-in-out forwards;
}

.ERP-Wrap .calendar-Wrap .slide-right {
    animation: slideInFromTop 0.3s ease-in-out forwards;
}


.ERP-Wrap .calendar-Wrap .rbc-month-row,
.ERP-Wrap .calendar-Wrap .rbc-month-view,
.ERP-Wrap .calendar-Wrap .rbc-header,
.ERP-Wrap .calendar-Wrap .rbc-day-bg {
    border: none !important;
}

.ERP-Wrap .calendar-Wrap .rbc-day-bg.rbc-off-range-bg {
    background: rgba(0, 0, 0, 0.1);
}

.ERP-Wrap .calendar-Wrap .rbc-day-bg.rbc-today {
    display: none;

}

.rbc-now {
    background-color: #007bff;
    border-radius: 5px;
}

.rbc-button-link:hover {
    text-decoration: underline;
}

.rbc-button-link:focus {
    background: #007bff;
    width: 100%;
    border-radius: 1rem;
}

.ERP-Wrap .calendar-Wrap .toolbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.ERP-Wrap .calendar-Wrap .toolbar-container button {
    padding: 5px 10px;
    background: none;
    border: none;
    cursor: pointer;
}

.ERP-Wrap .calendar-Wrap .toolbar-container button:hover {
    background: rgba(0, 0, 0, 0.1);
}


/* src/css/ERPCalendar.css */
.calendar-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.calendar-Wrap {
    flex-grow: 1;
}

.calendar-content {
    flex-grow: 1;
    height: 100%;
}


@keyframes slideInFromBottom {
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideInFromTop {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}