.Projects_Container {
    padding: 1rem;
    padding-top: 5rem;
    display: grid;
    text-align: center;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}
.Projects_Container .Title{
    text-align: center;
    color: #fff;
    margin-bottom: 1.5rem;
    user-zoom: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    font-size: 20px;
}

.Projects_Container .project-card {
    background: rgba(0, 0, 0, 0.1);
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    display: flex;
    flex-direction: column;
}
.lightmode .Projects_Container .project-card {background:rgba(255, 255, 255, 0.1);}
.Projects_Container .project-card:hover {
    transform: translateY(-10px);
}

.Projects_Container .tech-stack img {
    width: 30px;
    margin: 0 5px;
}

.Projects_Container a {
    margin-top: 15px;
    padding: 10px 20px;
    background: #007BFF;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.Projects_Container a:hover {
    background: #0056b3;
}