.Poke_Container {
    padding: 1rem;
    padding-top: 5rem;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 100vh;
    width: 100vw;
    max-width: 1200px;
}

.lightmode .Poke_Container .Title {
    color: #333;
}
    .Poke_Container .Title {
    text-align: center;
    color: #fff;
    margin-bottom: 1.5rem;
    user-zoom: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    font-size: 20px;
}
.Poke_Container .Poke_Search{
 width: 100%;
    margin: 15px;
    padding: 5px;
    border-radius: 1rem;
}
.Poke_List_Wrap{
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;
    padding: 20px;
    height:80vh;
    overflow-y: scroll;
    width: 100%;
    justify-items: center;
}

/* .Scroll_Wrap{
    height:80vh;
    overflow-y: scroll;
} */

.darkmode .Poke_Container .Poke_List_Wrap .Poke_Wrap .Poke_Name {color: #fff;}
.Poke_Container .Poke_List_Wrap .Poke_Wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;   
    width: 200px;
    height: 200px;
    border: 1px solid #ccc;
    text-align: center;
    line-height: normal;
    border-radius: 5px;
    padding: 0.4rem;
    transition: transform 0.3s ease;
}
.Poke_Container .Poke_List_Wrap .Poke_Wrap:hover{

        transform: translateY(-10px);
}

.Poke_Container .Poke_List_Wrap .Poke_Wrap .Img_Wrap{
    display: flex;
    justify-content: center;
    width: 50%;
    height: 50%;
}

.Poke_List_Wrap .Poke_Wrap .Poke_Type_Wrap {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.Poke_List_Wrap .Poke_Wrap .Poke_Type_Wrap .Poke_Type {
    width: 100%;
    border: none;
    text-align: center;
    border-radius: 1rem;
    color: #333;
    font-weight: 600;
    align-items: center;
    margin: 0px 5px 0px 5px;
    
}



@keyframes slideUp {
    from {
        transform: translateY(100%); /* 시작 위치: 화면 아래 */
        opacity: 0;
    }
    to {
        transform: translateY(0); /* 최종 위치: 원래 위치 */
        opacity: 1;
    }
}

.Poke_Container .ModalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.Poke_Container .Modal {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    width: 80vw;
    margin: auto;
    border-radius: 10px 10px 0 0;
    animation: slideUp 0.5s ease-out;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    text-align: center;
    padding: 20px;
}

.Poke_Container .Modal .Modal_Close{
    position: absolute; 
    top: 15px; 
    right: 15px; 
    border: none; 
    background-color: transparent; 
    cursor: pointer;
    font-size: 1.5em; 
}
.Poke_Container .Modal .Modal_Close:hover {
    transform: scale(1.2); /* 크기를 약간 키워줌 */
    color: red; /* 텍스트 색상을 빨간색으로 변경 */
}
.Poke_Container .Modal .Poke_Type_Wrap {
    display: flex;
    justify-content: center;
    margin-top: 5px;
}

.Poke_Container .Modal .Poke_Type {
    width: 25%;
    border:none;
    text-align: center;
    border-radius: 5px;
    color: #000;
    font-weight: 600;
    align-items: center;
    margin: 0px 5px 0px 5px;
}

.Poke_Container .Modal .Cnt {
    display: flex;
    flex-direction: column;
    padding: 0 15% 0 15%;
}
.Poke_Container .Modal .Poke_WH_Wrap {
    display: flex;
    justify-content: center;
    margin-top: 5px;
}
.Poke_Container .Modal .Poke_WH_Wrap .Poke_WH {
    width: 100%;
    border: none;
    text-align: center;
    border-radius: 5px;
    color: #333;
    font-weight: 600;
    align-items: center;
    margin: 0px 5px 0px 5px;
}
.Poke_Container .Modal .PokemonSprites {
display: flex;
justify-content: center;
width: 100%;
}
.Poke_Container .Modal .Poke_Shape {
    display: flex;
    flex-direction: column;
    text-align: center;
    border-radius: 5px;
    color: #333;
    font-weight: 600;
    align-items: center;
    margin: 0px 5px 0px 5px;
}
.Poke_Container .Modal .Poke_Name {
    color: #333;
    font-size: 1rem;
}
.Poke_Container .Modal .EvolutionDetails {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 150px;
    overflow-y: auto;
}

.Poke_Container .Modal .EvolutionDetails .EvolutionPath{
display: flex;
justify-content: center;
}
.Poke_Container .Modal .EvolutionDetails .EvolutionPath{
display: flex;
justify-content: center;
}
.EvolutionArrow {
    text-align: center;
    font-size: 2rem;
    margin-top: 2rem;
}
.Loading {
    width: 1rem;
    height: 1rem;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}
.lightmode .Loading {
    border: 5px solid #9698A6;
    width: 1rem;
    height: 1rem;
    border-bottom-color: #fff;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 
    
@media (orientation: portrait) {
    /* 세로모니터용 */
    @media (min-width: 768px) {
        .Poke_List_Wrap {
            grid-template-columns: repeat(4, 1fr); 
        }
        .Poke_Detail {
            width: calc(100% / 4 - 20px); 
        }
    }
}

@media (max-width: 1024px) {
    .Poke_List_Wrap {
        grid-template-columns: repeat(4, 1fr); 
    }
    
    .Poke_Detail {
        width: calc(100% / 4 - 20px); 
    }
}
@media (max-width: 768px) {
    .Poke_List_Wrap {
        grid-template-columns: repeat(3, 1fr); 
    }
    
    .Poke_Detail {
        width: calc(100% / 4 - 20px); 
    }
}

@media (max-width: 664px) {
    .Poke_List_Wrap {
        grid-template-columns: repeat(2, 1fr);
    }
    .Poke_Detail {
        width: calc(100% / 2 - 20px); 
    }
}

@media (max-width: 480px) {
    .Poke_List_Wrap {
            grid-template-columns: repeat(2, 1fr);
            height: calc(80vh - 100px);
        }
    
        .Poke_Detail {
            width: calc(100% / 2 - 20px);
        }
    
        .Poke_Container .Poke_List_Wrap .Poke_Wrap {
            width: 35vw;
            height: 35vw;
        }
    
        .Poke_Container .Poke_List_Wrap .Poke_Wrap .Poke_Num {
            font-size: 0.7rem;
            top: -5px;
        }
    
        .Poke_Container .Poke_List_Wrap .Poke_Wrap img {
            top: -5px;
        }
        
        .Poke_Container .Poke_List_Wrap .Poke_Wrap .Poke_Name {
            top: -20px;
        }
    
        .Poke_Container .Poke_List_Wrap .Poke_Wrap .Poke_Type_Wrap {
            top: -20px;
        }
    
        .Poke_Container .Poke_List_Wrap .Poke_Wrap .Poke_Type {
            font-size: 0.7rem;
        }
        .Poke_Container .Modal{
            width: 100%;
        }

}

/* 타입별 색상 */
.normal { background-color: #A8A878; } /* 노말 */
.fighting { background-color: #C03028; } /* 격투 */
.flying { background-color: #A890F0; } /* 비행 */
.poison { background-color: #A040A0; } /* 독 */
.ground { background-color: #E0C068; } /* 땅 */
.rock { background-color: #B8A038; } /* 바위 */
.bug { background-color: #A8B820; } /* 벌레 */
.ghost { background-color: #705898; } /* 고스트 */
.steel { background-color: #B8B8D0; } /* 강철 */
.fire { background-color: #F08030; } /* 불 */
.water { background-color: #6890F0; } /* 물 */
.grass { background-color: #78C850; } /* 풀 */
.electric { background-color: #F8D030; } /* 전기 */
.psychic { background-color: #F85888; } /* 에스퍼 */
.ice { background-color: #98D8D8; } /* 얼음 */
.dragon { background-color: #7038F8; } /* 드래곤 */
.dark { background-color: #705848; } /* 악 */
.fairy { background-color: #EE99AC; } /* 페어리 */