.document-box {
  display: flex;
  width: 100%;
  height: 100%;

}
.lightmode .document-box h2{
  color: #333;
}
.document-box .locationWrap {
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.1);
padding: 1rem;
}

.document-box .addWrap {padding-left: 1rem; width: 100%;}

.document-box .fileWrap, 
.document-box .folderWrap{
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  max-width: 500px;
  align-items: center;
}

.document-box .fileWrap input, 
.document-box .folderWrap input{
width: 60%;
border: 1px solid#ccc;
}

.document-box button {
  background-color: #3A67EA; 
  border: none;
  color: white; 
  padding: 0.5rem; 
  text-align: center; 
  text-decoration: none; 
  display: inline-block; 
  cursor: pointer; 
  border-radius: 12px; 
  transition-duration: 0.4s; 
  width: 7rem;
}

.document-box button:hover {
  background-color: white; 
  color: black; 
  outline: 2px solid #3A67EA; 
}
.document-box li{
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.document-box li:hover{
  background: rgba(0, 0, 0, 0.1);
}
.document-box li button{
  width: auto;
  padding: 0.2rem;
}
.path-segment {
  display: block;
  margin-left: 20px;
}

.path-segment:first-child {
  margin-left: 0;
}

.path-link {
  color: #0000EE;
  cursor: pointer;
  text-decoration: underline;
}

.path-link:hover {
  text-decoration: none;
}