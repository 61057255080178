/* 기본 스타일 */
.ChartMakerWrap {
  padding: 1rem;
  padding-top: 50px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* max-height: calc(100vh - 121px);  */
  width: 100vw;
  max-width: 1200px;

}


.ChartMakerWrap .Title {
  text-align: center;
  color: #fff;
  margin-bottom: 1.5rem;
  user-zoom: none;
  user-select: none;
  font-size: 20px;
}



.table-container {
  overflow-x: auto;
  width: 100%;
  max-width: calc(100vw - 2rem);
}

.chart-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

/* 테이블 스타일 */
table {
  width: 100%;
  min-width: 600px;
  border-collapse: collapse;
}

table th,
table td {
  padding: 8px 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

table th {
  background-color: #f2f2f2;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
}

.btnWrap {
  display: flex;
  margin: 5px;
  width: 100%;
}

.btnWrap1 {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.btnWrap button {
  border: none;
  background: rgba(0, 0, 0, 0.1);
  padding: 10px;
  backdrop-filter: blur(30px);
  margin: 0px 5px 0px 5px;
  border-radius: 4px;
}

.btnWrap button:hover {
  border: none;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px;
  color: #fff;
}



table th span,
table th button {
  cursor: pointer;
}

table th button {
  border: none;
  font-size: 16px;
  width: 20px;
  height: 20px;
}

table th button:focus,
table th.Action button:focus {
  outline: none;
}

table th button:hover,
table th.Action button:hover {
  background-color: rgba(0, 0, 0, 0.1)
}

.sortup {
  color: #ff4f4f;
}

.sortdown {
  color: #007bff;
}

table td input {
  width: 80px;
  padding: 6px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

table tbody tr:hover {
  background-color: #f2f2f2;
}

table th.Action {
  width: 60px;
  text-align: center;
}

table th.Action button {
  background-color: #ff6b6b;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 14px;
}

.disabled-input {
  pointer-events: none;
  background-color: #f3f3f3;
  color: #d3d3d3;
}

.chartselect,
.addColInput {
  width: 200px;
  border-radius: 4px;
}

/* 정렬된 컬럼 표시 */
.sorted-columns {
  padding: 5px;
  display: flex;
  width: 200px;
  justify-content: space-around;
  font-size: 16px;
  line-height: 16px;
}

.TableTitleWrap {
  display: flex;
  flex-direction: column;
}

.TableTitleWrap div {
  display: flex;
  justify-content: space-around;
}

.TableTitleWrap div button {
  border: none;
  font-size: 16px;
  height: 20px;
  width: auto;
}


.print-chart-container {
  display: none;
}

/* 반응형 레이아웃 */
@media (max-width: 768px) {
  .ChartMakerWrap {
    max-width: 95vw;
    display: flex;
    align-items: flex-start;
  }

  .chart-container,
  .table-container {
    max-width: 93vw;
    overflow-y: auto;
  }
  .chart-container {
    overflow-y: hidden;
    max-height: 426px;
  }

  table {
    width: 95vw;
    /* 테이블 너비 자동 조정 */
  }
}

@media (max-width: 664px) {
  .ChartMakerWrap {
    max-width: 94vw;
    display: flex;
    align-items: flex-start;
  }

  .chart-container,
  .table-container {
    max-width: 93vw;
    overflow-y: auto;
  }
  .chart-container {
    overflow-y: hidden;
    max-height: 426px;
  }


  table {
    width: 90vw;
    font-size: 14px;
    /* 작은 화면에서 폰트 크기 조정 */
  }
}

@media (max-width: 480px) {

  .ChartMakerWrap {
    display: flex;
    align-items: flex-start;
  }

  .btnWrap button {
    font-size: 12px;
  }

  .chartselect,
  .addColInput {
    border-radius: 4px;
    font-size: 12px;
    width: 40%;
  }

  .chart-container,
  .table-container {
    max-width: 85vw;
    overflow-y: auto;
  }
  .chart-container {
    overflow-y: hidden;
    max-height: 426px;
  }

  table {
    width: auto;
    font-size: 12px;
    /* 매우 작은 화면에서 폰트 크기 조정 */
  }
}



/* 인쇄 모드 */
@media print {


  .ChartMakerWrap .Title {
    color: #000!important;
}
  html,
  body {
    margin: 0 !important;;
    padding: 0 !important;;
    width: 100vw !important;;
    height: 100vh !important;;
    overflow: visible !important;;
  }
 

  #printArea {
    display: flex !important;;
    flex-direction: column !important;;
    justify-content: space-around !important;;
    align-items: center !important;;
  }


 
  .table-container {

    width: 100% !important;
    height: 20vh !important;
    overflow: visible;
    display: block;
  }

  .chart-container {
    display: none;
  }
  .print-chart-container {
    display:block;
     height: 40vh!important;
    overflow: visible;
  }

  .recharts-legend-wrapper {
    position: absolute;
    top:34vh!important;
  }


  button,
  select,
  .Footer,
  header,
  .sorted-columns,
  .addColInput {
    visibility: hidden !important;;
  }

  table {
    table-layout: fixed !important;;
    width: 100% !important;;
  }

  table tr th,
  table tr td {
    border: 1px solid #000 !important;;
  }

  table td input {
    border: none !important;;
    text-align: center !important;;
    width: 100% !important;;
  }

  table th.no-value,
  table td.no-value,
  table tr th:last-child,
  table tr td:last-child {
    display: none !important;;
  }


}