.LHDetail_Container {
    padding: 1rem;
    padding-top: 5rem;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80vh;
    max-height: 100vh;
    width: 100vw;
   margin: 0 auto;
    gap: 15px;
    overflow-y: auto;
}
.LHDetail_Container .Title{
    margin-bottom: 1.5rem;
    color: #007BFF;
    user-zoom: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    font-size: 20px;
    width: 100%;
    border-bottom: 1px solid #007BFF;
    align-items: center;
    padding: 1rem;
    text-align: left;
}
.LHDetail_Container .Title:hover{
    cursor: pointer;
}
.LHDetail_Container .TitleWrap{
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid #333;
    padding: 0 1rem 1rem 1rem;
}
.lightmode .LHDetail_Container .Title2{color: #333;}
.LHDetail_Container .Title2{
    color: #fff;
    font-size: 1rem;
}
.LHDetail_Container .FileDownload{align-self: end;}
.LHDetail_Container .Tags{
    width: 100%;
    text-align: right;
}
.LHDetail_Container .flx{
    gap: 20px;
    align-self: end;
}
.LHDetail_Container .Loading {
    width: 1rem;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.lightmode .LHDetail_Container .Loading {
    border: 5px solid #9698A6;
    border-bottom-color: #fff;
}
.LHDetail_Container .post {
    width: 100%;
}
.LHDetail_Container .ql-syntax {
    background-color: #23241f;
    color: #f8f8f2;
    overflow: auto;
    color: inherit !important;
}


.LHDetail_Container p .hljs{background: none;color: inherit;}
.LHDetail_Container h3 .hljs{background: none;color: inherit;}
.LHDetail_Container .p code{ font-family: inherit; font-size: inherit; font-weight: inherit;}
.LHDetail_Container .h3 code{ font-family: inherit; font-size: inherit; font-weight: inherit;}
.LHDetail_Container .commentwrap {
    display: flex;
    width: 100%;
    position: relative;
}
.LHDetail_Container .commentwrap .comments{
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
}
.LHDetail_Container .commentwrap .comments .comment-txt{
    max-height: 80px; 
    max-width: 100%;
    overflow-y: auto; 
    white-space: pre-wrap; 
    word-wrap: break-word; 
}
.LHDetail_Container .commentwrap .comments strong{padding-right: 10px;}
.LHDetail_Container .commentwrap .comments .profile-pic{width: 20px; height: 20px;margin-right: 10px;}
.LHDetail_Container .commentwrap .comment-date{
    white-space: nowrap;
    font-size: 0.9rem;
    padding-right: 2rem;
}
.LHDetail_Container  .commentwrap .comment-del-btn{
    padding: 0;
    margin: auto;
    align-self: end;
    background: none;
    position: absolute;
    top: 0;
    right: 0;
}
.lightmode .LHDetail_Container  .commentwrap .comment-del-btn:hover{
    background: rgba(0, 0, 0, 0.1);
}
.LHDetail_Container  .commentwrap .comment-del-btn:hover{
    background: rgba(255, 255, 255, 0.1);
}
.LHDetail_Container  .commentwrap .comment-del-btn .material-symbols-outlined{font-size: 1rem; padding: 0.3px;}
.lightmode .LHDetail_Container  .commentwrap .comment-del-btn .material-symbols-outlined{color: #333;}
.LHDetail_Container .comment-section {
    align-self: start;
    width: 100%;
}
.LHDetail_Container .comment-input .add-comment{
    position: absolute;
    right: 0;
    width: 6rem;
    height: 100%;
}
.LHDetail_Container .comment-input {
    align-self: start;
    display: flex;
    width: 100%;
    gap: 15px;
    position: relative;
}
.LHDetail_Container .comment-input button{
    position: absolute;
    right: 0;
    height: 100%;
    padding: 3px 6px;
    width: 10%;
    background: #007bff;
    color: white;
    cursor: pointer;    
}
.LHDetail_Container .comment-input button svg{object-fit: contain; height: 100%; width: 100%;}
.LHDetail_Container .comment-input button:hover{    background: #0056b3;}
.LHDetail_Container .comment-input .comment{
    align-self: start;
    resize: none;
    width: 100%;
}

.LHDetail_Container button{
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    background: #007bff;
    color: white;
    cursor: pointer;
    align-self: flex-end;
}

.LHDetail_Container .quillwrap{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow: hidden;
}
.LHDetail_Container .quill {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow: hidden;
}
.LHDetail_Container .ql-container {
    border-radius: 0 0 4px 4px;
    height: 85%;
    overflow: auto;
}
.LHDetail_Container  .ql-editor {
    border: 1px solid #ccc;
    border-top: none;
    padding: 10px;
    color: inherit !important;
}
/* 반응형 디자인 */
@media (max-width: 480px) {
    .LHDetail_Container button {
        padding: 3px 6px;

    }
}
@media (max-width: 768px) {



    .LHDetail_Container .TitleWrap { 
        flex-direction: column;
    }
    .LHDetail_Container .commentwrap {
        flex-direction: column;
        align-items: flex-start;
        position: relative;
    }

    .LHDetail_Container .commentwrap .comments {
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
    }

    .LHDetail_Container .commentwrap .comment-date{
        align-self: flex-start;
    }
    .LHDetail_Container .commentwrap .comment-del-btn {
        align-self: flex-start;
        position: absolute;
        right: 0px; 
    }

    .LHDetail_Container .comment-input .comment {
        padding: 6px;
        font-size: 0.9rem;
    }

    .LHDetail_Container .comment-input .add-comment {
        width: 100%;
        padding: 0.7rem;
        font-size: 0.9rem;
    }
    .LHDetail_Container .comment-input button {width: 15%;}
}