  .seat-selection-modal {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    border-radius: var(--border-radius);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 16px;
    }
    

  .seat-selection-modal .stage{
    width: 100%;
    text-align: center;
    background-color: #ddd;
    margin-bottom: 20px;
    font-size: 3rem;
  }
  .seatwrap{
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  .seat-row {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .seat {
    margin: 0 5px;
    padding: 5px 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    cursor: pointer;
    font-size: 1.5rem;
  }
  
  .seat.selected  {
    background-color: #4CAF50;
    color: white;
  }
  
  .seat.reserved {
    background-color: #ff6347; /* 예약된 좌석은 붉은색으로 표시 */
    color: white;
    cursor: not-allowed; /* 선택할 수 없음을 나타내는 커서 */
  }
  
  .modal-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .confirm-button, .close-button {
    margin: 0 5px;
    padding: 5px 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .close-button {
    background-color: #f44336;
  }

  /* 반응형 레이아웃 */
@media (max-width: 768px) {}

@media (max-width: 664px) {}

@media (max-width: 480px) {

  .seat-selection-modal .stage {
    font-size: 1.5rem;
}

}
