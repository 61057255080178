.reservation-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--card-bg-color);
  padding: 1rem;
  border-radius: var(--border-radius);
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  width: 100%;
  height: 100%;
  z-index: 999;
}

.reservation-modal-content {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background: var(--card-bg-color);
  padding: 1rem;
  border-radius: var(--border-radius);
  transition: transform 0.3s ease;
  animation: slideUp 0.5s ease forwards;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  width: 70%;
  height: 70%;
  justify-content: space-between;
}

.reservation-modal-content .modalwrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.reservation-modal-content .modalwrap .optiontitle {
  width: 120px;
  padding-right: 16px;
  font-size: 3rem;
  color: #fff;
}

.reservation-modal-content .modalwrap .items {
  font-size: 3rem;
  width: 70%;
  border: none;
  border-radius: 3px;
  padding-left: 16px;
}
.reservation-modal-content .modalwrap .items:hover {
  outline: 2px solid #fff;
}
.lightmode .reservation-modal-content .modalwrap .items:hover {
  outline: 2px solid #333;
}

.reservation-modal-content .btnwrap {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #9698A6;
  width: 100%;
}

.reservation-modal-content .btn1 {
  margin-top: 32px;
  padding: 16px;
  font-size: 3rem;
  border: none;
  border-radius: 3px;
  color: #333;
}
.reservation-modal-content .btn1:hover {
  outline: 2px solid #fff;
}

.lightmode .reservation-modal-content .btn1:hover {
  outline: 2px solid #333;
}

.reservation-modal-content form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  color: #fff;
}

.reservation-modal-content form h1 {
  font-size: 30px;
  text-align: center;
}

.reservation-modal-content form .flx {
  display: flex;
}

.reservation-modal-content .titleimg {
  width: 200px;
  object-fit: contain;
}

/* 반응형 레이아웃 */
@media (max-width: 768px) {}

@media (max-width: 664px) {}

@media (max-width: 480px) {
  .reservation-modal-content {
    width: 90vw;
  }

  .reservation-modal-content .modalwrap .optiontitle {
    width: 120px;
    padding-right: 16px;
    font-size: 1.5rem;
  }

  .reservation-modal-content .modalwrap .items {
    font-size: 1rem;
  }

  .reservation-modal-content .btn1 {

    font-size: 1.5rem;
  }
  .seat-selection-modal .stage {
    font-size: 1.5rem;
}

}