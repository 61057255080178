.TicketBookWrap {
    padding: 1rem;
    padding-top: 50px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: calc(100vh - 121px);
    width: 100vw;
    max-width: 1200px;
}

.TicketBookWrap .Title {
    text-align: center;
    color: #fff;
    margin-bottom: 1.5rem;
    user-zoom: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    font-size: 20px;
}





.TicketBookWrap .ContentsList {
    height: 100%;
    width: 100%;
    overflow-y: auto;
}

.TicketBookWrap .ContentsList .ContentsWrap {
    display: flex;
    flex-direction: column;
    padding: 5px;
}

.TicketBookWrap .ContentsWrap .contents {
    background: var(--card-bg-color);
    padding: 1rem;
    border-radius: var(--border-radius);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    margin-bottom: 30px;
    display: flex;
    height: 100%;
    width: 100%;
}
.TicketBookWrap .ContentsWrap .contents:hover{
    outline: 2px solid #fff;
}
.lightmode .TicketBookWrap .ContentsWrap .contents:hover{
    outline: 2px solid #333;
}

.TicketBookWrap .ContentsWrap .contents .imgwrap {
    height: 20rem;
    width: 20rem;
    padding-left: 0;
}

.TicketBookWrap .ContentsWrap .contents .imgwrap .titleimg {
    height: 100%;
    width: 100%;
    border-radius: 20px;
}

.TicketBookWrap .ContentsWrap .contents div {
    height: 100%;
    width: 100%;
    padding-left: 20px;
}

.TicketBookWrap .ContentsWrap .contents div p {
    padding-top: 30px;
}


/* 티켓 관리 마스터용 css */
.TicketMaster {
    padding: 1rem;
    padding-top: 50px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: calc(100vh - 121px);
    width: 100vw;
    max-width: 1200px;
    overflow-y: auto;
}

.TicketMaster .TicketLists {
    padding-top: 16px;
    overflow-y: auto;
    width: 100%;
}

.TicketMaster form {
    padding-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.TicketMaster form .category {
    margin-right: 30px;
}

.TicketMaster form form {
    padding-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.TicketMaster .add {
    margin-top: 30px;
    width: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.TicketMaster .ContentsList {
    max-height: 80vh;
    overflow-y: scroll;
    width: 100%;
}

.TicketMaster .btn3s {
    width: 30%;
}