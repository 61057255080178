  .notes-container {
      padding: 20px;
      flex: 1;
      overflow-y: auto;
      width: 100%;
      }

      .notes-container textarea{
        resize: none;
      }
      
   .notes-container .message-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }
  .notes-container .sendedMSG,
  .notes-container .recevedMSG
  {padding-top: 1rem;
height: 40%;
overflow-y: auto;}

  .notes-container .sendedMSG h2,
  .notes-container .recevedMSG h2
  {padding-bottom: 1rem;}

   .notes-container .message-form select,
   .notes-container .message-form input,
   .notes-container .message-form textarea,
   .notes-container .message-form button {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
  }
  
   .notes-container .message-form button {
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
   .notes-container .message-form button:hover {
    background-color: #45a049;
  }
  
  .message-item {
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .message-item:hover {
    background-color: #f1f1f1;
  }
  
  .flx {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .message-content {
    margin-top: 10px;
    border-top: 1px solid #ddd;
    padding-top: 10px;
  }
  