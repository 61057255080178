.board-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  }
  
  .post-form,
  .edit-form {
    margin-bottom: 20px;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
  }
  .post-textarea {
    resize: none; 
    width: 100%; 
    height: 100px; 
    margin-bottom: 10px; 
    padding: 8px; 
    box-sizing: border-box; 
    border: 1px solid #ccc; 
    border-radius: 4px; 
  }
  .postWrap{
    overflow-y: auto;
    height: 100%;
  }
  .ModalBackdrop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  .Modal{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    left: 0;
    right: 0;
    bottom: 50%;
    background-color: white;
    width: 80vw;
    margin: auto;
    border-radius: 10px 10px 0 0;
    animation: slideUp 0.5s ease-out;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    text-align: center;
    padding: 20px;
  }
  
  .post-form input,
  .edit-form input,
  .edit-form textarea {
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
    box-sizing: border-box;
  }
  
  .post-form button,
  .edit-form button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .post {
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  
  .post:last-child {
    border-bottom: none;
  }
.spar{
    justify-content: space-around;
  }
.spbt{
    justify-content: space-between;
  }
  
  .post h3 {
    margin-top: 0;
  }
  
  .post-author {
    font-style: italic;
    margin-bottom: 10px;
  }
  
  .post-actions button {
    margin-right: 5px;
  }